export const AccountReducer = (state, action) => {
  switch (action.type) {
    case "setShowOver":
      return {
        ...state,
        showOver: action.payload,
      };
    case "setShowLogin":
      return {
        ...state,
        showLogin: action.payload,
      };
    case "setShowSignup":
      return {
        ...state,
        showSignup: action.payload,
      };
    case "setFooterPaymentImages":
      return {
        ...state,
        FooterPaymentImages: action.payload,
      };
    case "setLoged":
      return {
        ...state,
        loged: action.payload,
      };
    case "setAnalyticsUrl":
      return {
        ...state,
        analytics_url: action.payload,
      };
    case "setLoading":
      return {
        ...state,
        loading: action.payload,
      };
    case "setUsername":
      return {
        ...state,
        username: action.payload,
      };
    case "setLastname":
      return {
        ...state,
        lastname: action.payload,
      };
    case "setFirstname":
      return {
        ...state,
        firstname: action.payload,
      };
    case "setUserId":
      return {
        ...state,
        userId: action.payload,
      };
    case "setAdmin":
      return {
        ...state,
        admin: action.payload,
      };
    case "setAdminToken":
      return {
        ...state,
        adminToken: action.payload,
      };
    case "setAdminLoading":
      return {
        ...state,
        adminLoading: action.payload,
      };
    case "setNumber":
      return {
        ...state,
        wtspNumber: action.payload,
      };
    case "setWhatsappNumbers":
      return {
        ...state,
        whatsappNumbers: action.payload,
      };
    case "setListAccCach":
      return {
        ...state,
        listAccCach: action.payload,
      };

    case "setSeller":
      return {
        ...state,
        isSeller: action.payload,
      };

    case "setEmail":
      return {
        ...state,
        email: action.payload,
      };
    case "setShowListAcc":
      return {
        ...state,
        showListAcc: action.payload,
      };
    case "setShowswitchAccount":
      return {
        ...state,
        showswitchAccount: action.payload,
      };
    case "sethasDateBirth":
      return {
        ...state,
        hasdateBirth: action.payload,
      };
    case "setopenRemindBirthday":
      return {
        ...state,
        openRemindBirthday: action.payload,
      };
    case "setHasSignedUp":
      return {
        ...state,
        openRemindBirthday: action.payload,
      };
    case "setHasLogedIn":
      return {
        ...state,
        openRemindBirthday: action.payload,
      };
    case "setisRegistrationFormOpen":
      return {
        ...state,
        isRegistrationFormOpen: action.payload,
      };
    case "setCategoriesFavoriteList":
      return {
        ...state,
        categoriesFavoriteList: action.payload,
      };
    case "setEarnedPointInfo":
      return {
        ...state,
        earnedPointInfo: action.payload,
      };

    case "setNeedUpdateInfo":
      return {
        ...state,
        needUpdateInfo: action.payload,
      };
    case "setClaimPointsPopup":
      return { ...state, claimPointsPopup: action.payload };

    default:
      return state;
  }
};
export const initialState = {
  needUpdateInfo: false,
  ClaimPointsPopup: false,

  showswitchAccount: false,
  loged: false,
  loading: true,
  showLogin: false,
  showOver: false,
  showSignup: false,
  username: "",
  lastname: "",
  firstname: "",
  userId: "",
  analytics_url: "",
  admin: false,
  adminToken: "",
  adminLoading: true,
  wtspNumber: "",
  email: "",
  FooterPaymentImages: "",
  isSeller: false,
  whatsappNumbers: "",
  showListAcc: false,
  listAccCach: [],
  hasdateBirth: true,
  openRemindBirthday: false,
  isRegistrationFormOpen: false,
  categoriesFavoriteList: null,
  earnedPointInfo: null,
};
