import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom"; // Combined imports
import _axios from "../axios";
import { AccountContext } from "../contexts/AccountContext";
import buildLink, { path } from "../urls";
import arrowRight from "../assets/images/arrow-right.svg"; // Ensure this image has an alt prop if used in <img />
import { BsFillCartCheckFill, BsShop, BsStarFill } from "react-icons/bs";
import { RiCoupon3Fill, RiUserFollowLine } from "react-icons/ri";
import { BsFillHeartFill } from "react-icons/bs";
import { HiLightBulb } from "react-icons/hi";
import { ImLocation } from "react-icons/im";
import {
  FaMoneyBillWave,
  FaUserAlt,
  FaWallet,
  FaTicketAlt,
  FaCheckCircle,
  FaCreditCard,
} from "react-icons/fa";
import { MdAvTimer, MdCardMembership, MdFeedback } from "react-icons/md";
import { MdHourglassEmpty } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import { CurrencyContext } from "../contexts/CurrencyContext";
import { BiGitRepoForked } from "react-icons/bi";
import ClaimPointsPopup from "./ClaimPointsPopup";
import { GiProfit } from "react-icons/gi";

function UserSidebar(props) {
  // const [data, setData] = useState(props.data);
  // const history = useNavigate();
  // const [state] = useContext(AccountContext);
  // const [showOrderArrow, setShowOrderArrow] = useState(false);
  // const [showAddArrow, setShowAddArrow] = useState(false);
  // const [showWishArrow, setShowWishArrow] = useState(false);
  // const [showBuyArrow, setShowBuyArrow] = useState(false);
  // const [showRecentlyViewedArrow, setShowRecentlyViewedArrow] = useState(false);
  // const [showFollowArrow, setShowFollowArrow] = useState(false);
  // const [showReviewCenterArrow, setshowReviewCenterArrow] = useState(false);
  // const [showProfileArrow, setShowProfileArrow] = useState(false);
  // const [showFeedBackArrow, setshowFeedBackArrow] = useState(false);
  // const [showsuggestionArrow, setshowsuggestionArrow] = useState(false);
  const dataInformation = useContext(CurrencyContext);
  const [state, dispatch] = useContext(AccountContext);

  const [activeStatus, setActiveStatus] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(
    JSON.parse(localStorage.getItem("openSections")) || {
      accountManagement: true,
      orders: false,
      membership: false,
      wishlistReviews: false,
      feedbackSuggestions: false,
    }
  );

  // Your existing state and effect hooks should be defined here
  const [active, setActive] = useState(
    localStorage.getItem("activeTab") || window.location.pathname.split("/")[2]
  );

  useEffect(() => {
    setActive(window.location.pathname.split("/")[2]);
  }, [window.location.pathname]);

  useEffect(() => {
    localStorage.setItem("activeTab", active);
  }, [active]);

  useEffect(() => {
    localStorage.setItem("openSections", JSON.stringify(isOpen));
  }, [isOpen]);

  // Initialize state for open sections

  // Set active status from URL query when component mounts
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const status = params.get("status");
    if (status) {
      setActiveStatus(status);
    }
  }, [location.search]);

  const toggleSection = (section) => {
    setIsOpen((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const handleStatusClick = (status) => {
    setActiveStatus(status); // Set the active status immediately
    navigate(`/account/orders?status=${status}`); // Navigate to the new status
  };

  const handleOpenPopup = () => {
    dispatch({ type: "setClaimPointsPopup", payload: true });
  };

  const handleClosePopup = () => {
    dispatch({ type: "setClaimPointsPopup", payload: false });
  };

  const isPopupOpen = state.claimPointsPopup;


  return (
    <aside className="bg-white h-screen left-0 pl-2" style={{ width: `${props.width ? props.width : '16.5rem'}` }}>
      <ul className="p-4 space-y-2">
        {/* Account Management Section */}
        <li
          className="flex justify-between items-center cursor-pointer group hover:bg-gray-100 pl-2 p-2 rounded"
          onClick={() => toggleSection("accountManagement")}
        >
          <span className="font-semibold text-lg">Account Management</span>
          <span className="text-dgreyBlack text-d24">
            {isOpen.accountManagement ? "-" : "+"}
          </span>
        </li>
        {isOpen.accountManagement && (
          <>
            <li>
              <Link
                to={`${path}/account/profileTest`}
                className={`pl-9 flex items-center h-10 ${
                  active === "profileTest" ? "text-dbase" : "text-dgrey1"
                } hover:text-dbase group`}
              >
                <FaUserAlt
                  className={`${
                    active === "profileTest" ? "text-dbase" : "text-dgrey1"
                  } group-hover:text-dbase`}
                />
                <span
                  className={`ml-2 ${
                    active === "profileTest" ? "font-semibold text-dbase" : ""
                  }`}
                >
                  Profile
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={`${path}/account/addresses`}
                className={`pl-9 flex items-center h-10 ${
                  active === "addresses" ? "text-dbase" : "text-dgrey1"
                } hover:text-dbase group`}
              >
                <ImLocation
                  className={`${
                    active === "addresses" ? "text-dbase" : "text-dgrey1"
                  } group-hover:text-dbase`}
                />
                <span
                  className={`ml-2 ${
                    active === "addresses" ? "font-semibold text-dbase" : ""
                  }`}
                >
                  Addresses
                </span>
              </Link>
            </li>
          </>
        )}

        {/* Status Section */}
        <li
          className="flex justify-between items-center cursor-pointer group hover:bg-gray-100 pl-2  p-2 rounded"
          onClick={() => toggleSection("orders")}
        >
          <span className="font-semibold text-lg">Status</span>
          <span className="text-dgreyBlack text-d24">
            {isOpen.orders ? "-" : "+"}
          </span>
        </li>
        {isOpen.orders && (
          <ul className="flex flex-col space-y-2 pl-4 ml-6">
            {["processing", "complete", "trash"].map((status) => {
              const displayName =
                status === "trash"
                  ? "Cancelled"
                  : status.charAt(0).toUpperCase() + status.slice(1);

              // Choose icon based on the status
              const Icon =
                status === "complete"
                  ? FaCheckCircle
                  : status === "processing"
                  ? MdAvTimer
                  : MdCancel;

              return (
                <li
                  key={status}
                  className={`group cursor-pointer flex items-center h-10 ${
                    activeStatus === status ? "text-dbase" : "text-dgrey1"
                  } hover:text-dbase`}
                  onClick={() => handleStatusClick(status)}
                >
                  <Icon
                    className={`text-xl ${
                      activeStatus === status ? "text-dbase" : "text-dgrey1"
                    } group-hover:text-dbase`}
                  />
                  <span
                    className={`ml-2 ${
                      activeStatus === status ? "font-semibold text-dbase" : ""
                    } group-hover:text-dbase`}
                  >
                    {displayName}
                  </span>
                </li>
              );
            })}
          </ul>
        )}

        {/* Membership Section */}
        <li
          className="flex justify-between items-center cursor-pointer group hover:bg-gray-100 pl-2  p-2 rounded"
          onClick={() => toggleSection("membership")}
        >
          <span className="font-semibold text-lg">Services</span>
          <span className="text-dgreyBlack text-d24">
            {isOpen.membership ? "-" : "+"}
          </span>
        </li>
        {isOpen.membership && (
          <>
            <li>
              <Link
                to={`${path}/account/memberShip`}
                className={`pl-9 flex items-center h-10 ${
                  active === "memberShip" ? "text-dbase" : "text-dgrey1"
                } hover:text-dbase group`}
              >
                <MdCardMembership
                  className={`${
                    active === "memberShip" ? "text-dbase" : "text-dgrey1"
                  } group-hover:text-dbase`}
                />
                <span
                  className={`ml-2 ${
                    active === "memberShip" ? "font-semibold text-dbase" : ""
                  }`}
                >
                  MemberShip
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={`${path}/account/wallet`}
                className={`pl-9 flex items-center h-10 ${
                  active === "wallet" ? "text-dbase" : "text-dgrey1"
                } hover:text-dbase group`}
              >
                <FaWallet
                  className={`${
                    active === "wallet" ? "text-dbase" : "text-dgrey1"
                  } group-hover:text-dbase`}
                />
                <span
                  className={`ml-2 ${
                    active === "wallet" ? "font-semibold text-dbase" : ""
                  }`}
                >
                  Wallet
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={`${path}/account/coupons`}
                className={`pl-9 flex items-center h-10 ${
                  active === "coupons" ? "text-dbase" : "text-dgrey1"
                } hover:text-dbase group`}
              >
                <FaTicketAlt
                  className={`${
                    active === "coupons" ? "text-dbase" : "text-dgrey1"
                  } group-hover:text-dbase`}
                />
                <span
                  className={`ml-2 ${
                    active === "coupons" ? "font-semibold text-dbase" : ""
                  }`}
                >
                  Coupons
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={`${path}/account/checkin`}
                className={`pl-9 flex items-center h-10 ${
                  active === "checkin" ? "text-dbase" : "text-dgrey1"
                } hover:text-dbase group`}
              >
                <FaCheckCircle
                  className={`${
                    active === "checkin" ? "text-dbase" : "text-dgrey1"
                  } group-hover:text-dbase`}
                />
                <span
                  className={`ml-2 ${
                    active === "checkin" ? "font-semibold text-dbase" : ""
                  }`}
                >
                  Check In
                </span>
              </Link>
            </li>

            {dataInformation &&
              dataInformation.data &&
              dataInformation.data.use_claim_points_service && (
                <li>
                  <button
                    onClick={handleOpenPopup}
                    className="pl-9 flex items-center h-10 text-dgrey1 hover:text-dbase group"
                  >
                    <GiProfit className="text-dgrey1 group-hover:text-dbase" />
                    <span className="ml-2">Claim Points</span>
                  </button>

                  <ClaimPointsPopup
                    isOpen={isPopupOpen}
                    onClose={handleClosePopup}
                  />
                </li>
              )}
          </>
        )}

        {/* Products & Reviews Section */}
        <li
          className="flex justify-between items-center cursor-pointer group hover:bg-gray-100 pl-2  p-2 rounded"
          onClick={() => toggleSection("wishlistReviews")}
        >
          <span className="font-semibold text-lg">Products & Reviews</span>
          <span className="text-dgreyBlack text-d24">
            {isOpen.wishlistReviews ? "-" : "+"}
          </span>
        </li>
        {isOpen.wishlistReviews && (
          <>
            <li>
              <Link
                to={`${path}/account/wishlist`}
                className={`pl-9 flex items-center h-10 ${
                  active === "wishlist" ? "text-dbase" : "text-dgrey1"
                } hover:text-dbase group`}
              >
                <BsFillHeartFill
                  className={`${
                    active === "wishlist" ? "text-dbase" : "text-dgrey1"
                  } group-hover:text-dbase`}
                />
                <span
                  className={`ml-2 ${
                    active === "wishlist" ? "font-semibold text-dbase" : ""
                  }`}
                >
                  Wishlist
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={`${path}/account/reviewCenter`}
                className={`pl-9 flex items-center h-10 ${
                  active === "reviewCenter" ? "text-dbase" : "text-dgrey1"
                } hover:text-dbase group`}
              >
                <BsStarFill
                  className={`${
                    active === "reviewCenter" ? "text-dbase" : "text-dgrey1"
                  } group-hover:text-dbase`}
                />
                <span
                  className={`ml-2 ${
                    active === "reviewCenter" ? "font-semibold text-dbase" : ""
                  }`}
                >
                  Reviews
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={`${path}/account/recentlyViewed`}
                className={`pl-9 flex items-center h-10 ${
                  props.active === "reviews" ? "text-dbase" : "text-dgrey1"
                } hover:text-dbase group`}
              >
                <MdAvTimer
                  className={`${
                    props.active === "recentlyViewed"
                      ? "text-dbase"
                      : "text-dgrey1"
                  } group-hover:text-dbase`}
                />
                <span
                  className={`ml-2 ${
                    props.active === "recentlyViewed"
                      ? "font-semibold text-dbase"
                      : ""
                  }`}
                >
                  Recently Viewed
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={`${path}/account/buyagain`}
                className={`pl-9 flex items-center h-10 ${
                  props.active === "buyagain" ? "text-dbase" : "text-dgrey1"
                } hover:text-dbase group`}
              >
                <BsFillCartCheckFill
                  className={`${
                    props.active === "buyagain" ? "text-dbase" : "text-dgrey1"
                  } group-hover:text-dbase`}
                />
                <span
                  className={`ml-2 ${
                    props.active === "buyagain"
                      ? "font-semibold text-dbase"
                      : ""
                  }`}
                >
                  Buy Again
                </span>
              </Link>
            </li>
          </>
        )}

        {/* Feedback Section */}
        <li
          className="flex justify-between items-center cursor-pointer group hover:bg-gray-100 pl-2 p-2 rounded"
          onClick={() => toggleSection("feedbackSuggestions")}
        >
          <span className="font-semibold text-lg">Feedback</span>
          <span className="text-dgreyBlack text-d24">
            {isOpen.feedbackSuggestions ? "-" : "+"}
          </span>
        </li>
        {isOpen.feedbackSuggestions && (
          <li>
            <Link
              to={`${path}/account/feedback`}
              className={`pl-9 flex items-center h-10 ${
                active === "feedback" ? "text-dbase" : "text-dgrey1"
              } hover:text-dbase group`}
            >
              <MdFeedback
                className={`${
                  active === "feedback" ? "text-dbase" : "text-dgrey1"
                } group-hover:text-dbase`}
              />
              <span
                className={`ml-2 ${
                  active === "feedback" ? "font-semibold text-dbase" : ""
                }`}
              >
                Feedback
              </span>
            </Link>
          </li>
        )}
      </ul>
      <Link
        to={`${path}/account/shops`}
        className={`pl-5 flex items-center h-10 ${
          active === "shops" ? "text-dbase" : "text-dgrey1"
        } hover:text-dbase group`}
      >
        <BsShop
          className={`${
            active === "shops" ? "text-dbase" : "text-dgrey1"
          } group-hover:text-dbase`}
        />
        <span
          className={`ml-2 ${
            active === "shops" ? "font-semibold text-dbase" : ""
          }`}
        >
          Shops
        </span>
      </Link>
    </aside>
  );
}

export default UserSidebar;
