import {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
  useMemo,
} from "react";
import _axios from "../axios";
import PointsLoader from "../components/PointsLoader";
import SeoHandler from "../components/SeoHandler";
import WidgetsLoop from "../components/WidgetsLoop";
import { AccountContext } from "../contexts/AccountContext";
import buildLink from "../urls";
import { useWindowDimensions } from "../components/TopHeader";
import download from "../assets/images/download.gif";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { CurrencyContext } from "../contexts/CurrencyContext";
import ScrollToTop from "react-scroll-to-top";
import { AiOutlineRadiusBottomright } from "react-icons/ai";
import SingleProducts from "../components/SingleProduct";
import useDeviceSize from "../components/useDeviceSize";
function Home(props) {
  const host_url = props.host;
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [state,dispatch] = useContext(AccountContext);
  const [width] = useDeviceSize();
  const observer = useRef(null);
  const [bannerStats, setBannerStats] = useState([]);
  const dataRef = useRef([]);
  const productRef = useRef([]);
  const [products, setProducts] = useState(false);

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!hasMore || isLoading) return;

    const fetchData = async () => {
      setIsLoading(true);

      const obj = {
        view: window.innerWidth > 650 ? "web_desktop" : "web_mobile",
        limit: 10,
        page: page,
      };

      let link =
        buildLink("home", undefined, undefined, window.config["site-url"]) +
        "&source_id=1";
      if (state.admin) {
        link += "&clear";
      }

      try {
        const response = await _axios.post(link, obj);
        if (response?.data?.success) {
          let newData = response?.data?.data?.widgets || [];

          if (response?.data?.data?.meta?.type === "No_widgets") {
            newData = response?.data?.data?.widgets?.items || [];
            setProducts(true);
            productRef.current = [...productRef.current, ...newData];
          } else {
            dataRef.current = [...dataRef.current, ...newData];
          }

          setHasMore(newData.length > 0);
        } else {
          setHasMore(false);
        }
      } catch (error) {
        setHasMore(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page, state.admin]);


  const ProductList = useMemo(() => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-4  max-sm:grid-cols-3 max-[450px]:grid-cols-2 max-xs:grid-cols-2 max-md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 2xl:grid-cols-6 p-2 gap-2 mt-1 mb-1 bg-white md:grid-cols-6 max-lg:grid-cols-6">
        {productRef.current.map((item, index) => (
          <div
            className="flex flex-col items-center"
            key={index}
            ref={
              index === productRef.current.length - 1 ? lastElementRef : null
            }
          >
            <SingleProducts item={item} isWidget={true} />
          </div>
        ))}
      </div>
    );
  }, [productRef.current]);

  const WidgetsList = useMemo(() => {
    return dataRef.current.map((widget, index) => {
      if (dataRef.current.length === index + 1) {
        return (
          <div
            className="theHome"
            ref={lastElementRef}
            key={widget.mobile_widget_id}
          >
            <WidgetsLoop widget={widget} bannerStats={bannerStats} />
          </div>
        );
      } else {
        return (
          <div className="" key={widget.mobile_widget_id}>
            <WidgetsLoop widget={widget} bannerStats={bannerStats} />
          </div>
        );
      }
    });
  }, [lastElementRef, bannerStats]);

  useEffect(() => {
    if (!state.admin) {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }

      if (
        window.location.host === "www.ishtari.com" ||
        window.location.host === "next.ishtari.com" ||
        window.location.host === "ishtari-mobile.com"
      ) {
        gtag("event", "conversion", {
          send_to: "AW-991347483/pc3dCIaww44YEJuG29gD",
        });
      } else if (
        window.location.host === "www.ishtari.com.gh" ||
        window.location.host === "next.ishtari.com.gh"
      ) {
        gtag("event", "conversion", {
          send_to: "AW-10993907106/31DICLmKppEYEKLrpvoo",
        });
      }
    }
  }, []);

  // useEffect(() => {
  //   if (state.admin && showStats) {
  //     const banner_image_ids = [];
  //     dataRef.current.forEach((widget) => {
  //       if (widget.display === "grid" || widget.display === "slider") {
  //         widget.items.forEach((item) => {
  //           banner_image_ids.push(item.banner_image_id);
  //         });
  //       }
  //     });

  //     const obj = {
  //       source_type: "home",
  //       banner_image_ids: banner_image_ids.join(","),
  //     };

  //     axiosServer.post(buildLink("banner_stats"), obj).then((response) => {
  //       setBannerStats(response.data.data);
  //     });
  //   }
  // }, [state.admin, showStats]);

  return (
    <div>
      <div className={`overflow-hidden container`}>
        {WidgetsList}
        {ProductList}
        {isLoading && <PointsLoader />}
      </div>
    </div>
  );
}

export default Home;
