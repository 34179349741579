
import React, { useContext, useEffect, useState } from "react";

import { AiOutlineLeft, AiOutlineStar, AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import _axios from "../axios";
import buildLink from "../urls";
import UserSidebar from "../components/UserSidebar";
import UserSidebarMobile from "../components/UserSidebarMobile";
import PointsLoader from "../components/PointsLoader";
import SeoHandler from "../components/SeoHandler";
import { useWindowDimensions } from "../components/TopHeader";
import { AccountContext } from "../contexts/AccountContext";
import faq from '../assets/images/faq.png'
import favicon from '../assets/images/favicon.png';
import ReactPaginate from "react-paginate";

import { FaUser, FaCalendarAlt, FaCheckCircle, FaDollarSign, FaRegStickyNote } from 'react-icons/fa';

export default function MenmberShipDetails() {
  const [state, setState] = useState({
    data: {
      points_history: []
    }
  });
  const {width} = useWindowDimensions();
  const [accountState] = useContext(AccountContext);
  const [totalPage, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const router = useNavigate();
  const username = accountState?.user?.username || accountState?.username;
  const lastname = accountState?.user?.lastname || accountState?.lastname;
  const [loading, setLoading] = useState(true);
  const [membershipInfo, setMembershipInfo] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalHistories, setTotalHistories] = useState(0);


  const [isLargeScreen, setIsLargeScreen] = useState(false);


  useEffect(() => {
    const checkScreenSize = () => {
      const width = window.innerWidth;
      setIsLargeScreen(width >= 1024);
    };

    window.addEventListener("resize", checkScreenSize);
    checkScreenSize(); 

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);



  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const resp = await _axios.get(buildLink("memberShip") + `&page=${page}`);
        const { total_history_pages, total_histories } = resp.data.data;
        setTotalPages(total_history_pages);
        setTotalHistories(total_histories);
        setState(resp.data);
        if (!accountState.loged) {
          router.push("/");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };
    fetchData();
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  }, [state.loged, router, page]);

  const handlePageClick = (data) => {
    setPage(data.selected + 1); // Adjust to start from page 1
  };

  function goTo() {
    router("/account/memberShip");
  }

  function pageSetter(page) {
    setPage(page["selected"] + 1);
    router(
      `/account/memberShip?&page=${page["selected"] + 1}&limit=${limit}`
    );
  }
  const getCurrentPageData = () => {

    return state.data.points_history
  };
  return (
    <div className="mobilecontainer sm:overflow-hidden">
      <div className=" text-dblack">
      <SeoHandler
        data={{
          title: "Member Ship Details",
        }}
        
      />
        <div className=" pb-2">
          <div className="flex-row md:flex">
            <div>
              {width > 768 ? (
                <UserSidebar active={"memberShip"}  />
              ) : (
                <UserSidebarMobile active={"memberShip"} />
              )}
            </div>
            {loading ? (
              <div className="flex mx-auto justify-center w-fit">
                <PointsLoader />
              </div>
            ) : (
              <div className="w-full h-full lg:ml-4">
                <div
                  className="text-white px-5 h-1/3 rounded-b-lg"
                  style={{
                    backgroundColor: "#f5f5f1",
                    backgroundImage:
                      "linear-gradient(215deg, #f5f5f1 -50%, #e50914 90%)",
                  }}
                >
                  <p
                    className="flex items-center text-lg pr-semibold py-4 w-full"
                    onClick={() => router.push("/account/memberShip")}
                  >
                    <AiOutlineLeft className="mx-2 cursor-pointer" /> Membership
                  </p>
                  <div className="hidden max-md:flex-row w-1/2 max-md:w-full max-md:flex max-md:items-center md:flex-row md:w-full md:flex md:items-center text-dblack -mt-10 justify-end">
                    <div className="text-d14 ml-2">
                      <span
                        onClick={() => setMembershipInfo(true)}
                        className="text-dgrey2 font-light text-sm cursor-pointer"
                      >
                        <div className="rounded-full text-sm">
                          <img
                            src={faq}
                            width={50}
                            className="bg-dbase1 rounded-full p-4 text-sm"
                          />
                        </div>
                      </span>
                    </div>
                  </div>
                  {membershipInfo && (
                    <div
                      className="fixed w-screen h-full min-h-screen top-0 left-0 bg-opacity-30 bg-dblack z-30"
                      onClick={() => setMembershipInfo(false)}
                    ></div>
                  )}
                  <div
                    className={`top-0 bg-white right-0 sm:w-1/2 w-10/12 min-h-screen transform fixed h-full z-40 ease-in-out duration-300 overflow-y-scroll ${
                      membershipInfo ? "translate-x-0" : "translate-x-full"
                    }`}
                  >
                    <div className="relative -top-9 p-6">
                      <div className="absolute z-40 text-dblack">
                        <div className="p-6 bg-white">
                          <AiOutlineClose className="absolute right-5 t-2 cursor-pointer font-extrabold" size={25} onClick={() => setMembershipInfo(false)}  />
                          <h2 className="text-2xl font-bold mb-4">
                            Membership Benefits
                          </h2>
                          <p className="mb-4">
                            At ishtari, we believe in rewarding our loyal
                            customers. Our membership program is designed to
                            provide you with exciting benefits and exclusive
                            offers based on your purchases.
                          </p>
                          <h3 className="text-xl font-semibold mb-2">
                            Earning Points:
                          </h3>
                          <p className="mb-4">
                            Every time you check-in, you earn points. The number
                            of points you earn depends on the consecutivity of
                            check-ins. If you check-in every day regularly, the
                            more points you accumulate.
                          </p>
                          <h3 className="text-xl font-semibold mb-2">
                            Membership Tiers:
                          </h3>
                          <p className="mb-4">
                            As you collect points, you'll progress through
                            different membership tiers. There are four
                            membership tiers available:
                          </p>
                          <div className="mobile:grid grid-cols-1 min-h-max px-2">
                            {state?.data?.point_ranges?.map((p) => (
                              <div className="flex justify-between my-2 bg-white px-3">
                                <div className="flex items-center">
                                  <AiOutlineStar
                                    className={"w-5"}
                                    style={{
                                      color: p.title.includes("Platinum")
                                        ? "E5E4E2"
                                        : p.title.includes("Diamond")
                                        ? "B9ECFC"
                                        : p.title.replace(" Member", ""),
                                    }}
                                  />{" "}
                                  <span className="pl-2">{p.title}</span>
                                </div>
                                {p.start === 1500 ? (
                                  <div className="flex-col p-5 text-l">
                                    {">= " + p.start}
                                  </div>
                                ) : (
                                  <div className="flex-col p-5 text-l">
                                    {p.start}-{p.end}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row pb-7 relative">
                    <div className="flex flex-col px-5 w-full">
                      <div className="text-center text-white pl-5 text-2xl space-x-2 w-full">
                        My Points: {state.data.points_balance}
                      </div>
                      <div className="text-center text-white pl-5 text-lg space-x-2 w-full">
                        Pending Points:{" "}
                        {state.data.pending_points_balance}
                      </div>
                      <div className="flex mt-2 px-12 pb-3">
                        <div className="h-1 w-full bg-dTransparentWhite1 mt-2">
                          <div
                            className="h-1 bg-white"
                            style={{
                              width: (state?.data?.current_point_precentage * 100) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
             
                {isLargeScreen?(
                  <>
               
               <div
                  className="mb-5 p-4 bg-white rounded-lg overflow-auto"
                  style={{
                    transform: "translateY(-1%)",
                    width: "99%", 
                    margin: "0 auto" 
                  }}
                >

            
                      <div  className="flex justify-between px-3 mb-2">
                        <div className="">Last month</div>{" "}
                        <div className="">
                          {state?.data?.points_earned_last_month}
                        </div>
           
                    </div>
                 <div className="text-xl mb-2">Points History</div> 
        
                 {getCurrentPageData().map((data, index) => (
  <div key={index}>
    {data.is_user == 0 ? (
      <div className="bg-white mb-3 grid grid-cols-3 gap-4 text-lg">
        {/* First column: Name */}
        <div className="flex items-start sm:items-center gap-2 sm:mb-0">
          <div
            className="rounded-full p-2 text-sm"
          >
           <img
                src={favicon}
                width={53}
              
              />
          </div>
          <div>
            <div className="text-lg">
             Ishatri
            </div>
            <div className="text-sm text-dgreyQtyProduct">
              {data.date_added}
            </div>
          </div>
        </div>

        {/* Second column: Type */}
        <div className="text-lg sm:text-base pr-semibold text-dgreen flex gap-1 mt-3">
         <FaCheckCircle className="mt-1"/> {data.type}
        </div>

        {/* Third column: Points and Description */}
        <div className="mt-3 text-lg">
          <div className="flex gap-1">
           <FaDollarSign className="mt-1"/> Amount:{" "}
            <span
              className={`${
                data.points > 0 ? "text-dgreen" : "text-dbase"
              }`}
            >
              {data.points}
            </span>{" "}
          </div>
          {data.description.length > 0 && (
            <div className="flex gap-1">
             <FaRegStickyNote className="mt-1"/> Description:{" "}
              <span className="text-dgreyProduct">{data.description}</span>
            </div>
          )}
        </div>
      </div>
    ) : (
      <div className="bg-white rounded-md shadow-md mb-3 p-4">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center gap-2">
            <div className="rounded-full text-sm">
              <img
                src={favicon}
                width={53}
                className="bg-dbase rounded-full p-4 text-sm"
              />
            </div>
            <div>
              <div>ishtari</div>
              <div className="text-sm text-dgreyQtyProduct">{data.date_added}</div>
            </div>
          </div>
          <div className="pr-semibold text-dgreyProduct">{data.type}</div>
        </div>
        <div>
          Amount:{" "}
          <span
            className={`${
              data.points > 0 ? "text-dgreen" : "text-dbase"
            }`}
          >
            {data.points}
          </span>{" "}
        </div>
        {data.description.length > 0 && (
          <div>
            Description:{" "}
            <span className="text-dgreyProduct">{data.description}</span>
          </div>
        )}
      </div>
    )}
  </div>
))}

                </div>
                  </>
                 )
                :(
                  <>
                  
                                  <div
                  className="mb-5 p-3 bg-white rounded-lg overflow-auto"
                  style={{
                    transform: "translateY(-1%)",
                    width: "95%", 
                    margin: "0 auto" 
                  }}
                >

            
                      <div  className="flex justify-between px-3 mb-2">
                        <div className="">Last month</div>{" "}
                        <div className="">
                          {state?.data?.points_earned_last_month}
                        </div>
           
                    </div>
                 <span className="text-xl">Points History</span> 
                  {getCurrentPageData().map((data, index) => (
                    <div key={index}>
                      {data.is_user == 0 ? (
                        <div className="bg-white   px-3 p-1">
                        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between ">
                          <div className="flex items-start sm:items-center gap-2 mb-3 sm:mb-0">
                             <div
                            className="rounded-full p-1 text-sm"
                          >
                          <img
                                src={favicon}
                                width={53}
                               className="mt-4"
                              />
                          </div>
                          <div>
                            <div className="text-lg">
                            Ishatri  <span className="text-dgreen">{data.type}</span>
                            </div>
                              <div className="text-sm text-dgreyQtyProduct">
                                {data.date_added}
                              </div>
                             
                              <div className="flex gap-1">
                              <FaDollarSign className="mt-1"/> 
                            <span
                              className={`${
                                data.points > 0
                                  ? "text-dgreen"
                                  : "text-dbase"
                              }`}
                            >
                              {data.points}
                            </span>{" "}
                          </div>
                          {data.description.length > 0 && (
                            <div className="flex gap-1">
                                <FaRegStickyNote className="mt-1"/> 
                              <span className="text-dgreyProduct">
                                {data.description}
                              </span>
                            </div>
                          )}
                            </div>
                          </div>
                         
                        </div>                      
                         
                         
                        </div>
                      ) : (
                        <div className="bg-white rounded-md shadow-md mb-3 p-4">
                          <div className="flex items-center justify-between mb-3">
                            <div className="flex items-center gap-2">
                              <div className="rounded-full text-sm">
                                <img
                                  src={favicon}
                                  width={53}
                                  className="bg-dbase rounded-full p-4 text-sm"
                                />
                              </div>
                              <div>
                                <div>ishtari </div>
                                <div className="text-sm text-dgreyQtyProduct">
                                  {data.date_added}
                                </div>
                              </div>
                            </div>
                           
                          </div>
                          <div>
                            Amount:{" "}
                            <span
                              className={`${
                                data.points > 0
                                  ? "text-dgreen"
                                  : "text-dbase"
                              }`}
                            >
                              {data.points}
                            </span>{" "}
                          </div>
                          {data.description.length > 0 && (
                            <div>
                              Description:{" "}
                              <span className="text-dgreyProduct">
                                {data.description}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                  </>
                  )}
                
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={totalPages}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  activeClassName={"active-pagination-category"}
                  containerClassName={'pagination'}
                  className={"category-pagination"}
                  forcePage={page - 1} // Adjust to start from page 1
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
