
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function FilterItem({ type, item, onClick }) {
    const location = useLocation();
  const [selected, setSelected] = useState(false);

  // Destructure query parameters
  const queryParams = new URLSearchParams(location.search);

  const filter_categories = queryParams.get("filter_categories");
  const filter_manufacturers = queryParams.get("filter_manufacturers");
  const filter_sellers = queryParams.get("filter_sellers");
  const filter_options = queryParams.get("filter_options");
  const adv_filters = queryParams.get("adv_filters");

  useEffect(() => {
    isFilterSelected(type, item.id);
  }, [type, item, location.search]);

  // Check if a filter is selected
  const isFilterSelected = (type, filterId) => {
    const filtersMap = {
      filter_categories,
      filter_manufacturers,
      filter_sellers,
      filter_options,
      adv_filters,
    };

    const selectedFilters = filtersMap[type]?.split(",") || []; // Split if comma-separated
    const isSelectedFilters = selectedFilters.includes(String(filterId));
  
    setSelected(isSelectedFilters);
  };

  const getClassNames = (filter) => {
    if (filter.name === "Color" || filter.name === "Light color") {
      return selected
        ? "rounded-full border border-dblue"
        : "rounded-full border relative border-dgreyRate cursor-pointer hover:shadow";
    }

    if (
      filter.name === "Shoes Size" ||
      filter.name === "Size by Age" ||
      filter.name === "jeans Size" ||
      filter.name === "Socks"
    ) {
      return selected
        ? "border rounded text-dblue border-dblue"
        : "border rounded relative border-dgreyRate cursor-pointer hover:shadow p-2";
    }

    return "";
  };

  const handleFilterClick = () => {
    setSelected(!selected);
    onClick(item.id)
    
  };

  return (
    <div
      className="flex items-center cursor-pointer hover:text-dblue"
      onClick={handleFilterClick}
    >
      <input
        type="checkbox"
        checked={selected}
        onChange={handleFilterClick}
        className={getClassNames(item)}
      />
      <h1 className="mx-2 text-d14 font-light select-none">{item.name}</h1>
      <span className="text-d14 text-right font-light opacity-70">
        ({item.count})
      </span>
    </div>
  );
}
