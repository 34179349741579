import { useContext, useEffect, useState } from "react";
import UserSidebar from "../components/UserSidebar";
import _axios from "../axios";
import buildLink from "../urls";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountContext } from "../contexts/AccountContext";
import { useWindowDimensions } from "../components/TopHeader";
import UserSidebarMobile from "../components/UserSidebarMobile";
import Loader from "../components/PointsLoader";
import SeoHandler from "../components/SeoHandler";
import "react-step-progress/dist/index.css";
import ImageCoupon from "../assets/images/coupon.png";
import { BsInfoCircleFill } from "react-icons/bs";
import { CgSandClock } from "react-icons/cg";
import SingleCoupon from "../components/singleCoupon";
import { sanitizeHTML } from "../Utils";
function Coupons() {
  const { width } = useWindowDimensions();
  const [data, setData] = useState();
  const [buttonActive, setButtonActive] = useState("1");
  const trigger = useRef(null);
  const [showPopup, setShowpopup] = useState(false);
  const location = useLocation();
  const modal = useRef(null);

  const history = useNavigate();
  const [state, dispatch] = useContext(AccountContext);

  const [loading, setLoading] = useState(true);
  const params = new URLSearchParams(location.search);
  const customerId = params.get("customer_id");
  const code = params.get("code");
  const date = params.get("date");

  const handleClick = (event) => {
    event.preventDefault();
    setButtonActive(event.target.id);
  };

  useEffect(() => {
    if (!state.loading && !state.loged) {
      if (code == null || code == undefined) {
        history("/");
      } else {
        dispatch({ type: "setShowOver", payload: true });
        dispatch({ type: "setShowLogin", payload: true });
        dispatch({ type: "setShowSignup", payload: false });
      }
    } else if (state.loged) {
      if (code !== null || code !== undefined) {
        _axios
          .post(
            buildLink("redeemCoupon", undefined, window.innerWidth) +
              "&customer_id=" +
              customerId +
              "&code=" +
              code +
              "&date=" +
              date
          )
          .then((response) => {
            // if(response.data.success){
            history("/account/coupons");
            // }
          });
      }
      getCoupons();
    }
  }, [state.loading, code]);

  const getCoupons = () => {
    _axios
      .get(buildLink("coupons", undefined, window.innerWidth))
      .then((response) => {
        if (response?.data?.success) {
          console.log(response.data.data);
          setData(response.data.data);
          setLoading(false);
          if (!state.loged) {
          }
        } else {
          setLoading(false);
        }
      });
  };

  return (
    <div className=" text-dblack">
      <SeoHandler
        data={{
          title: "Coupons",
        }}
      />
      <div className="pb-2">
        <div className="flex-row md:flex">
          <div
            className={`w-full mb-4 md:w-1/5  -ml-3 ${
              width <= 650 ? "hidden" : ""
            }`}
          >
            <UserSidebar active={"coupons"} />
          </div>

          {loading ? (
            <div className=" mx-auto flex justify-center w-fit">
              <Loader />
            </div>
          ) : (
            <div className="flex relative justify-center  overflow-hidden sm:my-5  w-full">
              <div className="w-full md:px-0  mb-5 relative">
                <div>
                  <div className="relative w-full  mobile:flex">
                    <img src={ImageCoupon} className="w-full hidden md:block cursor-pointer"/>
                    <button
                      ref={trigger}
                      onClick={() => setShowpopup(true)}
                      className="absolute right-5 top-3 max-md:right-3 "
                    >
                      <BsInfoCircleFill className=" text-white drop-shadow-md " />
                    </button>
                    <div className="absolute custom-scrollbar gap-5 z-5 flex items-center w-full md:justify-center  overflow-x-auto   max-md:w-full max-md:-bottom-6  -bottom-4 cursor-pointer">
                      <button
                        id="1"
                        onClick={handleClick}
                        className={` ${
                          buttonActive === "1"
                            ? " bg-dbase text-white "
                            : "bg-white text-dblack "
                        }  border border-dgreyZoom   transition-all duration-300  text-sm shadow-md px-4 py-1.5 rounded-md`}
                      >
                        Available
                      </button>
                      <button
                        onClick={handleClick}
                        id="2"
                        className={` ${
                          buttonActive === "2"
                            ? " bg-dbase text-white "
                            : "bg-white text-dblack "
                        }  border border-dgreyZoom   transition-all duration-300  text-sm shadow-md px-4 py-1.5 rounded-md`}
                      >
                        Redeemed
                      </button>
                      <button
                        onClick={handleClick}
                        id="3"
                        className={` ${
                          buttonActive === "3"
                            ? " bg-dbase text-white "
                            : "bg-white text-dblack "
                        }  min-w-fit border border-dgreyZoom   transition-all duration-300  text-sm shadow-md px-4 py-1.5 rounded-md`}
                      >
                        Expires Soon
                      </button>
                      <button
                        onClick={handleClick}
                        id="4"
                        className={` ${
                          buttonActive === "4"
                            ? " bg-dbase text-white "
                            : "bg-white text-dblack "
                        }  border border-dgreyZoom   transition-all duration-300  text-sm shadow-md px-4 py-1.5 rounded-md`}
                      >
                        Expired
                      </button>
                    </div>
                  </div>
                </div>

                {buttonActive === "1" ? (
                  !data.Available.length == 0 ? (
                    <div className="container  my-10 pb-5 w-full  h-fit grid grid-cols-3 max-md:grid-cols-1 max-lg:grid-cols-2 align-middle place-items-center  max-mobile:grid-cols-1 gap-5 relative max-md:text-center max-md:justify-center  overflow-y-auto">
                      {data &&
                        data.Available.map((coupon) => {
                          return (
                            <SingleCoupon
                              coupon={coupon}
                              type="available"
                              getCoupons={getCoupons}
                            />
                          );
                        })}
                    </div>
                  ) : (
                    <div className=" my-5 flex flex-col justify-center  gap-5 align-middle text-center w-full  pt-10">
                      <div className=" w-fit mx-auto">
                        <CgSandClock className=" text-7xl text-dbase" />
                      </div>
                      <h2 className=" text-xl font-bold">
                        No Available Coupons here
                      </h2>
                    </div>
                  )
                ) : buttonActive === "2" ? (
                  !data.Redeemed.length == 0 ? (
                    <div className="container  my-10 pb-5 w-full  h-fit grid grid-cols-3 max-md:grid-cols-1 max-lg:grid-cols-2 align-middle place-items-center  max-mobile:grid-cols-1 gap-5 relative max-md:text-center max-md:justify-center  overflow-y-auto">
                      {data &&
                        data.Redeemed.map((coupon) => {
                          return (
                            <SingleCoupon
                              coupon={coupon}
                              type="redeemed"
                              getCoupons={getCoupons}
                            />
                          );
                        })}
                    </div>
                  ) : (
                    <div className=" my-5 flex flex-col justify-center  gap-5 align-middle text-center w-full  pt-10">
                      <div className=" w-fit mx-auto">
                        <CgSandClock className=" text-7xl text-dbase" />
                      </div>
                      <h2 className=" text-xl font-bold">
                        No Redeemd Coupons here
                      </h2>
                    </div>
                  )
                ) : buttonActive === "3" ? (
                  !data["Expires Soon"].length == 0 ? (
                    <div className="container  my-10 pb-5 w-full  h-fit grid grid-cols-3 max-md:grid-cols-1 max-lg:grid-cols-2 align-middle place-items-center  max-mobile:grid-cols-1 gap-5 relative max-md:text-center max-md:justify-center  overflow-y-auto">
                      {data &&
                        data["Expires Soon"].map((coupon) => {
                          return (
                            <SingleCoupon
                              coupon={coupon}
                              type="expiresSoon"
                              getCoupons={getCoupons}
                            />
                          );
                        })}
                    </div>
                  ) : (
                    <div className=" my-5 flex flex-col justify-center  gap-5 align-middle text-center w-full  pt-10">
                      <div className=" w-fit mx-auto">
                        <CgSandClock className=" text-7xl text-dbase" />
                      </div>
                      <h2 className=" text-xl font-bold">
                        No Expires Coupons here
                      </h2>
                    </div>
                  )
                ) : !data.Expired.length == 0 ? (
                  <div className="container  my-10 pb-5 w-full  h-fit grid grid-cols-3 max-md:grid-cols-1 max-lg:grid-cols-2 align-middle place-items-center  max-mobile:grid-cols-1 gap-5 relative max-md:text-center max-md:justify-center  overflow-y-auto">
                    {data &&
                      data.Expired.map((coupon) => {
                        return (
                          <SingleCoupon
                            coupon={coupon}
                            type="expired"
                            getCoupons={getCoupons}
                          />
                        );
                      })}
                  </div>
                ) : (
                  <div className=" my-5 flex flex-col justify-center  gap-5 align-middle text-center w-full  pt-10">
                    <div className=" w-fit mx-auto">
                      <CgSandClock className=" text-7xl text-dbase" />
                    </div>
                    <h2 className=" text-xl font-bold">
                      No Expired Coupons here
                    </h2>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {showPopup && (
        <div
          onClick={() => setShowpopup(false)}
          className=" fixed left-0 top-0 right-0 bottom-0  bg-dblack bg-opacity-40 z-50 "
        ></div>
      )}
      <div
        className={`${
          showPopup
            ? " top-0  scale-100 opacity-100"
            : " -top-32 scale-0 opacity-0"
        } z-50 fixed px-5   rounded-md max-md:w-[350px] max-md:h-[300px] left-0 top-0 bottom-0 py-5 right-0  transition-opacity duration-300 bg-white my-auto mx-auto w-[450px] h-[350px]`}
      >
        <div className=" w-full h-full relative justify-center text-center flex flex-col gap-4">
          {data && (
            <div
              id="desc"
              className=" text-left  "
              dangerouslySetInnerHTML={{
                __html: sanitizeHTML(data.description),
              }}
            />
          )}
          <button
            onClick={() => setShowpopup(false)}
            className="  bg-dbase mt-4 text-white px-4 py-1 rounded-md text-xl"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default Coupons;
