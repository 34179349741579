import { LazyLoadImage } from "react-lazy-load-image-component";
import ExpressImage from "../assets/images/express.png";
import marketImage from "../assets/images/market.svg";
import newImage from "../assets/images/new-1.png";

import { useEffect, useRef, useState } from "react";
import _axios from "../axios";
import {
  useNavigate,
  useLocation,
  useParams,
  Navigate,
} from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import buildLink, { path, pixelID } from "../urls";
import { AccountContext } from "../contexts/AccountContext";
import { useContext } from "react";
import { Link } from "react-router-dom";
import ImageFilter from "react-image-filter";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Cookies from "js-cookie";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import { Pagination, Autoplay } from "swiper";
import { useProductContext } from "../contexts/ProductContext";
import { useWindowDimensions } from "./TopHeader";
import { AiOutlineLike, AiFillLike, AiFillStar } from "react-icons/ai";
import { HiStar } from "react-icons/hi";

import placeholdersrc from "../assets/product_placeholder.png";
import DOMPurify from "dompurify";
import StarRating from "react-svg-star-rating";
import { slugify } from "../Utils";
import axios from "axios";
import { CartContext } from "../contexts/CartContext";
import { MdAddShoppingCart } from "react-icons/md";
function SingleProducts(props) {
  const [loadingAddToCart, setLoadingAddTocart] = useState(false);
  const { item, addToCart, topSelling, isWidget, carousel, noAddCart } = props;
  const [cart, dispatch] = useContext(CartContext);
  const [cartData, setCartData] = useState();
  const [loading, setLoading] = useState(false);
  const [state] = useContext(AccountContext);
  const location = useLocation();
  const dragging = props.dragging;
  const history = useNavigate();
  const [fetching, setFetching] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [likeNumber, setLikeNumber] = useState(Number(props.item.num_likes));
  const [copied, setCopied] = useState(false);
  const source_type =
    location.pathname === "/"
      ? "home"
      : location.pathname.startsWith("/category") ||
        location.pathname.includes("c=")
      ? "category"
      : location.pathname.startsWith("/seller") ||
        location.pathname.includes("s=")
      ? "seller"
      : location.pathname.startsWith("/manufacturer") ||
        location.pathname.includes("m=")
      ? "manufacturer"
      : location.pathname.startsWith("/latest")
      ? "new_arrival"
      : location.pathname.startsWith("/account/buyagain")
      ? "buyagain"
      : location.pathname.startsWith("/account/recentlyViewed")
      ? "recentlyViewed"
      : "";
  const source_type_id = useParams().id;
  const swiperRef = useRef();
  const onInit = (Swiper) => {
    swiperRef.current = Swiper;
  };
  const { setProductHolder } = useProductContext();
  const { width } = useWindowDimensions();

  const handleMouseEnter = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay = true;
      swiperRef.current.params.autoplay.delay = 1000;
    }
  };

  const handleMouseLeave = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.stop();
      swiperRef.current.slideTo(1);
    }
  };

  function ToSeoUrl(url) {
    // make the url lowercase
    var encodedUrl = url.toString().toLowerCase();

    encodedUrl = encodedUrl
      .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
      .replace("%", parseInt(""));

    // replace & with and
    encodedUrl = encodedUrl.replace(/\s+/g, "-");

    // remove invalid characters
    encodedUrl = encodedUrl.replace("..", "");

    // remove duplicates
    encodedUrl = encodedUrl.replace("/", "-");

    // trim leading & trailing characters
    encodedUrl = encodedUrl.replace("---", "-");
    encodedUrl = encodedUrl.replace("--", "-");
    encodedUrl = encodedUrl.replace("100%", "").replaceAll("#", "");
    encodedUrl = encodedUrl.replace("/", "");

    encodedUrl = encodeURIComponent(encodedUrl);

    return encodedUrl;
  }

  async function getProductData(e) {
    e.preventDefault();
    setFetching(true);
    const product_id = props.item.product_id.toString();
    const product_name = props.item.name
      .replaceAll(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
      .replaceAll(/\s+/g, "-")
      .replaceAll("..", "")
      .replaceAll("/", "-")
      .replaceAll("---", "-")
      .replaceAll("--", "-")
      .replaceAll("/", "")
      .replaceAll("%", parseInt("%"));

    // await _axios.get(buildLink("product",undefined,state.admin) + product_id).then((response) => {
    //   setFetching(false);
    const index = props.products && props.products.indexOf(product_id);
    // if (state.admin) {

    //     index: index,
    //   });
    // } else {
    history(`${path}/${product_name}/p=${product_id}`, {
      state: {
        source_type: source_type,
        source_type_id: source_type_id,
        fromSearch: props.fromSearch ? true : false,
      },
    });
  }
  // });
  // }

  // useEffect(() => {
  //   props.likedData?.map((l) => {
  //     if (l === Number(props.item.product_id)) {
  //       setIsLiked(true);
  //     }
  //   });
  // }, []);

  // function nFormatter(num) {
  //   const digits = 0;
  //   const lookup = [
  //     { value: 1, symbol: "" },
  //     { value: 1e3, symbol: "k" },
  //     { value: 1e6, symbol: "M" },
  //     { value: 1e9, symbol: "G" },
  //     { value: 1e12, symbol: "T" },
  //     { value: 1e15, symbol: "P" },
  //     { value: 1e18, symbol: "E" },
  //   ];
  //   const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  //   var item = lookup
  //     .slice()
  //     .reverse()
  //     .find(function (item) {
  //       return num >= item.value;
  //     });
  //   return item
  //     ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
  //     : "0";
  // }

  // function addLike(id) {
  //   const obj = {
  //     product_id: id,
  //   };
  //   _axios
  //     .post(buildLink("insertLike", undefined, window.innerWidth), obj)
  //     .then((response) => {
  //       if (response.data.success) {
  //         setLikeNumber(likeNumber + 1);
  //       }
  //     });
  //   console.log("added");
  // }

  // function removeLike(id) {
  //   const obj = {
  //     product_id: id,
  //   };

  //   _axios
  //     .post(buildLink("deleteLike", undefined, window.innerWidth), obj)
  //     .then((response) => {
  //       if (response.data.success) {
  //         if (likeNumber !== 0) {
  //           setLikeNumber(likeNumber - 1);
  //         }
  //       }
  //     });
  //   console.log("removed");
  // }

  async function addProductToCart(e, product_id, name) {
    // setLoading(product_id);
    setLoadingAddTocart(true);
    if (props?.item?.check_if_has_options) {
      history(
        `${path}/${name
          .replaceAll(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
          .replaceAll("%", "")
          .replaceAll(/\s+/g, "-")
          .replaceAll("..", "")
          .replaceAll("/", "-")
          .replaceAll("---", "-")
          .replaceAll("--", "-")
          .replaceAll("100%", "")
          .replaceAll("#", "")
          .replaceAll("/", "")}/p=${product_id}`
      );
    }
    var quantity = 1;
    e.preventDefault();
    let obj = {
      product_id,
      quantity,
    };

    let error = "";
    _axios
      .post(
        buildLink("cart", undefined, window.innerWidth) + "&source_id=1",
        obj
      )
      .then(async (response) => {
        const data = response.data;
        if (data.success !== true) {
          setLoadingAddTocart(false);
        } else {
          dispatch({
            type: "setProduct",
            payload: {
              name: data?.data?.product.name,
              image: data?.data?.product.thumb,
            },
          });

          if (!state.admin) {
            const advancedMatching = {
              em: data?.data?.social_data?.email,
              fn: data?.data?.social_data?.firstname,
              ln: data?.data?.social_data?.lastname,
              external_id: data?.data?.social_data?.external_id,
              country: data?.data?.social_data?.country_code,
              fbp: Cookies.get("_fbp"),
              client_ip_address: data?.data?.social_data?.ip,
              client_user_agent: data?.data?.social_data?.user_agent,
            };
            ReactPixel.init(pixelID, advancedMatching, {
              debug: true,
              autoConfig: false,
            });
            ReactPixel.pageView();
            ReactPixel.fbq("track", "PageView");

            // ReactPixel.track("ViewContent", {
            //   content_type: "product",
            //   content_ids: [product_id],
            //   content_name: data?.data?.social_data?.name,
            //   value: data?.data?.social_data?.value,
            // //  event_id: data?.data?.social_data?.event_id,
            //   currency: data?.data?.social_data?.currency,
            //   // country: data?.data?.social_data?.country_code,

            // }, {eventID: data?.data?.social_data?.event_id} );

            window.fbq(
              "track",
              "ViewContent",
              {
                content_type: "product",
                content_ids: [product_id],
                content_name: data?.data?.social_data?.name,
                value: data?.data?.social_data?.value,
                currency: data?.data?.social_data?.currency,
              },
              { eventID: data?.data?.social_data?.event_id }
            );
          }

          var dataSocial = data.data.social_data;
          dataSocial["fbp"] = Cookies.get("_fbp");
          dataSocial["fbc"] = Cookies.get("_fbc");
          dataSocial["ttp"] = Cookies.get("_ttp");
          dataSocial["link"] = window.location.href;
          dataSocial["view_type"] = "product";
          dataSocial["view_type_id"] = product_id;

          if (
            source_type === "" ||
            source_type === null ||
            typeof source_type === "undefined"
          ) {
            dataSocial["ignore"] = false;
          } else {
            dataSocial["source_type"] = source_type;
            dataSocial["source_type_id"] = source_type_id;
          }

          _axios
            .post(state.analytics_url, dataSocial)
            .then((response) => {
              const data = response.data;
              if (data.success === true) {
              }
            });

          await getProductCart();
          setLoadingAddTocart(false);
          // })
        }
      });

    // window.scrollTo({
    //     top: 0,
    //     behavior: "smooth",
    //   });

    async function getProductCart() {
      dispatch({
        type: "loading",
        payload: true,
      });
      await _axios
        .get(buildLink("cart", undefined, window.innerWidth))
        .then((response_data) => {
          dispatch({
            type: "setProducts",
            payload: response_data.data?.data?.products,
          });
          dispatch({
            type: "setProductsCount",
            payload: response_data?.data?.data?.total_product_count,
          });
          dispatch({
            type: "setTotals",
            payload: response_data.data?.data?.totals,
          });
          dispatch({
            type: "loading",
            payload: false,
          });
          dispatch({
            type: "setAsidecart",
            payload: true,
          });
        });
    }
  }

  function getProductQuantity(productId) {
    const product = cart?.products?.find(
      (item) => item.product_id === productId
    );
    return product ? product.quantity : null;
  }

  function copyContent(e, sku) {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    //props.handleCopy(e);
    const el = document.createElement("input");
    el.value = sku;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    setTimeout(() => setCopied(false), 2500);
  }

  return (
    <Link
      to={{
        pathname: `${path}/${props.item.name
          .replaceAll(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
          .replaceAll("%", parseInt(""))
          .replaceAll(/\s+/g, "-")
          .replaceAll("..", "")
          .replaceAll("/", "-")
          .replaceAll("---", "-")
          .replaceAll("--", "-")
          .replaceAll("100%", "")
          .replaceAll("#", "")
          .replaceAll("/", "")}/p=${props.item.product_id}`,
        state: {
          source_type: source_type,
          source_type_id: source_type_id,
          fromSearch: props.fromSearch ? true : false,
        },
      }}
      className={` cursor-pointer ${props.isList && "mb-3 "}`}
      onClickCapture={props.click}
      // onClick={(e) => {
      //   dragging === false && getProductData(e);
      //   setProductHolder(props.item);
      // }}
    >
      {
        <div
          className={`mx-1  cursor-pointer border  border-dplaceHolder hover:shadow-dgreyProduct rounded-lg block relative overflow-hidden shadow-sm shadow-dplaceHolder bg-white h-auto max-sm:h-auto`}
          style={{
            border: props.noBorder ? "" : "0px solid #eee",
            width: !props.scroll ? (props.carousel ? "50vw" : "98%") : "50vw",
            marginRight: props.carousel ? "7.5px" : "0px",
            height: props.isList && "260px", // You can leave this if it's for a specific condition
          }}
        >
          {props.item.new &&
            window.config["site-url"] !== "https://www.ishtari.com.gh" && (
              <img
                src={newImage}
                className="absolute z-10 h-5 "
                alt="Express delivery"
              />
            )}

          {props.item.tag_image && (
            <div className=" ">
              <img
                src={props.item.tag_image}
                className="absolute right-0 z-10 "
                alt="New product"
                width={42}
                height={20}
              />
            </div>
          )}
          <div className={`${props.isList && "flex"}`}>
            <div
              className={` relative ${
                props.isList &&
                "flex-shrink-0  flex-grow-0 w-40 -my-4 -ml-4 mr-4"
              }`}
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
            >
              {props.item.quantity === "0" &&
                    !props?.item?.coming_soon_date && (
                      <div className="absolute inset-0 z-10 tracking-widest flex items-center justify-center bg-dgrey bg-opacity-50 lg:mb-8 mb-56">
                        <div className=" text-dbase w-full md:mb-56  md:pt-6 md:pb-3 bg-dgrey max-sm:bg-dinputBorder md:rounded-md shadow-lg text-center">
                          OUT OF STOCK
                        </div>
                      </div>
                    )}
              {props.item.quantity === "0" ? (
                <ImageFilter
                  image={props.item.thumb}
                  filter={"duotone"} // see docs beneath
                  colorOne={[96, 96, 96]}
                  colorTwo={[255, 255, 255]}
                />
              ) : !props?.isSlider ||
                props?.item?.images?.length === 0 ||
                !props?.item?.images ||
                props.exportProduct ? (
                <img
                  className="product-image-exp"
                  alt={props.item.name}
                  src={fetching ? placeholdersrc : props.item.thumb}
                  width="288"
                  height="396"
                  style={{
                    // backgroundImage:
                    //   'url("https://www.sari3.com/ishtaridemo/product_placeholder.png")',
                    backgroundSize: "cover",
                  }}
                  // placeholdersrc={placeholdersrc}
                />
              ) : (
                <Swiper
                  pagination={{
                    el: ".my-custom-pagination-div",
                    clickable: true,
                    renderBullet: (index, className) => {
                      return '<span class="' + className + '">' + "</span>";
                    },
                  }}
                  loop={true}
                  preventClicks={false}
                  allowTouchMove={width > 650 ? false : true}
                  // modules={[Pagination, Autoplay]}
                  ref={swiperRef}
                  autoplay={true}
                  onInit={onInit}
                  className="single-product-swiper"
                >
                  <SwiperSlide>
                    {" "}
                    <img
                      alt={props.item.name}
                      src={fetching ? placeholdersrc : props.item.thumb}
                      width="288"
                      height="396"
                      placeholdersrc={placeholdersrc}
                      style={{
                        // backgroundImage:
                        //   'url("https://www.sari3.com/ishtaridemo/product_placeholder.png")',
                        backgroundSize: "cover",
                      }}
                      className="product-image-exp"
                    />
                  </SwiperSlide>
                  {props?.item?.images?.slice(0, 2).map((image) => {
                    return (
                      <SwiperSlide key={image.mobile_image}>
                        {" "}
                        <img
                          alt={props.item.name}
                          src={fetching ? placeholdersrc : image.mobile_image}
                          width="288"
                          height="396"
                          // placeholdersrc={placeholdersrc}
                          style={{
                            // backgroundImage:
                            //   'url("https://www.sari3.com/ishtaridemo/product_placeholder.png")',
                            backgroundSize: "cover",
                          }}
                          className="product-image-exp"
                        />
                      </SwiperSlide>
                    );
                  })}
                  <div
                    className={`my-custom-pagination-div absolute left-0 z-50 right-0 ${
                      props?.item?.option_color_count &&
                      props?.item?.option_color_count > 1
                        ? "bottom-9"
                        : "bottom-3"
                    }`}
                  ></div>

                  {props?.item?.option_color_count &&
                  props?.item?.option_color_count > 1 ? (
                    <div className="flex items-center flex-col ">
                      <div
                        className="text-d12 absolute bottom-0 z-50 font-semibold mb-2 px-3 overflow-hidden whitespace-nowrap overflow-ellipsis w-auto"
                        style={{
                          borderRadius: "30px",
                          background: "rgb(239, 243, 253)",
                          border: "1px solid rgba(255, 255, 255, 0.7)",
                          maxWidth: width > 650 ? "45%" : "50%",
                          paddingTop: "2px",
                          paddingBottom: "2px",
                        }}
                      >
                        {props?.item?.option_color_count} Colours
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </Swiper>
              )}

              <div className=" absolute inset-0  w-full h-full">
                <div className=" w-full h-full px-2 py-1 flex flex-col justify-between relative">
                  <div></div>
                </div>
              </div>
            </div>

            <div
              className={` ${
                props.isList ? "flex flex-col pb-1 flex-1" : "px-2"
              } `}
            >
              <div
                className={`${props.item.quantity === "0" && "opacity-40"} ${
                  props.isList && "flex justify-between"
                }`}
              >
                <div className="flex justify-between mt-1">
                  <div className="flex flex-row align-middle justify-between relative">
                    <div className="my-auto">
                      {item?.nb_of_reviews > 0 && (
                        <div className="flex justify-center items-center">
                          <div
                            className="flex justify-center items-center rounded-full h-4 align-bottom px-0.5 space-x-0.5"
                            style={{
                              backgroundColor:
                                item?.rating >= 4.5
                                  ? "rgb(0,158,0)"
                                  : item?.rating < 4.5 && item?.rating >= 4
                                  ? "rgb(110, 159, 0)"
                                  : item?.rating < 4 && item?.rating >= 3.5
                                  ? "rgb(243, 153, 22)"
                                  : "rgb(246,90,31)",
                            }}
                          >
                            <div
                              className="text-d12 font-bold text-white"
                              style={{ paddingTop: "0.5px" }}
                            >
                              {item?.rating || "0.0"}
                            </div>
                            <AiFillStar className="text-white text-bold text-d11" />
                          </div>
                          <div className="font-light text-d11 pl-0.5">
                            ({item?.nb_of_reviews})
                          </div>
                        </div>
                      )}
                    </div>

                  </div>
                
                  {!noAddCart && (
                    <div
                      className="w-8 h-8 flex justify-center items-center mb-1 rounded-md shadow-sm bg-white border border-dplaceHolder"
                      onClick={(e) => addProductToCart(e, item?.product_id, item.name)}
                    >
                      {loading === item.product_id && !noAddCart ? (
                        <div className="flex items-center justify-center relative">
                          <div className="animate-ping h-1.5 w-1.5 bg-dblue rounded-full absolute"></div>
                        </div>
                      ) : (
                        <>
                          {getProductQuantity(item?.product_id) > 0 && (
                            <div className="w-4 h-4 bg-dbase1 text-white flex items-center justify-center rounded-full text-xs absolute -top-1 right-1 border border-white">
                              {getProductQuantity(item?.product_id)}
                            </div>
                          )}
                          <MdAddShoppingCart />
                        </>
                      )}
                    </div>
                  )}

                </div>

                {/* PUT IS AFTER _html "[brand name]  " +  */}
                <div className="h-12 overflow-hidden">
                  <span
                    className={`text-dblack ${
                      props.isList ? "text-base font-semibold" : "text-d13 "
                    }md:text-thin mb-1 h-10 font-semibold`}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(props.item.manufacturer_name),
                    }}
                  />
                  {props.isList && <br />}

                  {props?.item?.name?.split(" ")[0] ===
                    props.item.manufacturer_name &&
                  props.item.manufacturer_name !== undefined ? (
                    <span
                      className={`text-dblack ${
                        props.isList
                          ? "text-base leading-6"
                          : "ml-1 text-d13 md:text-thin font-light"
                      }   mb-1 h-10 `}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          props?.item?.name?.split(
                            props.item.manufacturer_name
                          )[1]
                        ),
                      }}
                    />
                  ) : (
                    <span
                      className={`text-dblack ${
                        props.isList
                          ? "text-base leading-6"
                          : "ml-1 text-d13 md:text-thin font-light"
                      }   mb-1 h-10 `}
                      dangerouslySetInnerHTML={{
                        __html: props.isList
                          ? props.item.full_name
                          : props.item.name,
                      }}
                    />
                  )}
                </div>

                <div className="">
                  <div className="w-full flex items-center  gap-1 md:gap-2">
                    {/* Price */}
                    {/* <span className="text-sm text-gray-800">GH¢</span>
                    <strong className="text-lg font-bold text-black">
                      {item.special !== "0" &&
                      item.special !== "" &&
                      item.special !== false
                        ? item.special.replace("GH¢", "").trim()
                        : item.price}
                    </strong> */}

                    <strong className="lg:text-d16 text-d14 font-bold text-black">
                      {item.special !== "0" &&
                      item.special !== "" &&
                      item.special !== false
                        ? item.special
                        : item.price}
                    </strong>
                    {/* Old Price */}
                    <div className="lg:text-d12 text-d11 text-gray-500 line-through">
                      {item.price.replace("GH¢", "").trim()}
                    </div>
                    {/* Discount */}
                    {item.saving > 0 && (
                      <div className="text-xs font-bold text-dbase bg-red-500 px-2 py-1 rounded-full">
                        -{item.saving}%
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* PRODUCT DESCRIPTION LIST */}
              {props.isList && props.item.description && (
                <div className="mt-2 overflow-ellipsis overflow-hidden w-full h-28 text-d12 text-dlabelColor">
                  {" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        DOMPurify.sanitize(
                          props.item.description.slice(0, 500)
                        ) + "...",
                    }}
                  ></div>
                </div>
              )}

              <div className="flex place-content-between mb-4 mt-4">
                <div
                  className={`w-5/12 ${
                    props.isList
                      ? "mt-auto pt-3 flex justify-between items-center"
                      : "flex flex-row justify-between"
                  } `}
                >
                  {!props.exportProduct ? (
                    props.item.market === "0" ? (
                      props.item.quantity === "0" ? (
                        <ImageFilter
                          className="h-4 aspect-auto"
                          image={ExpressImage}
                          filter={"duotone"}
                          colorOne={[96, 96, 96]}
                          colorTwo={[255, 255, 255]}
                        />
                      ) : (
                        <img
                          src={ExpressImage}
                          className="h-6 py-1"
                          alt="Express delivery"
                        />
                      )
                    ) : (
                      <img src={marketImage} className="h-6 py-1" alt="" />
                    )
                  ) : null}
                </div>
                <div
                  className={` text-d12 flex   items-center mt-4 ${
                    (item.special === "0" ||
                      item.special === "" ||
                      item.special === false) &&
                    "invisible"
                  } ${props.isList && "m-px"}`}
                >
                  <div className="absolute right-2">
                    {/* <div
                      className="w-8 h-8 flex justify-center items-center rounded-md shadow-sm bg-white border border-dplaceHolder"
                      onClick={(e) =>
                        addProductToCart(e, item?.product_id, item.name)
                      }
                    >
                      {loading === item.product_id ? (
                        <div className="flex items-center justify-center relative">
                          <div className="animate-ping h-1.5 w-1.5 bg-dblue rounded-full absolute"></div>
                        </div>
                      ) : (
                        <>
                          {getProductQuantity(item?.product_id) > 0 && (
                            <div className="w-4 h-4 bg-dbase1 text-white flex items-center justify-center rounded-full text-xs absolute -top-1 right-1 border border-white">
                              {getProductQuantity(item?.product_id)}
                            </div>
                          )}
                          <MdAddShoppingCart />
                        </>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="flex flex-row align-middle justify-between relative">
                {/* <div className="my-auto">
                  {item?.nb_of_reviews > 0 && (
                    <div className="flex justify-center items-center">
                      <div
                        className="flex justify-center items-center rounded-full h-4 align-bottom px-0.5 space-x-0.5"
                        style={{
                          backgroundColor:
                            item?.rating >= 4.5
                              ? "rgb(0,158,0)"
                              : item?.rating < 4.5 && item?.rating >= 4
                              ? "rgb(110, 159, 0)"
                              : item?.rating < 4 && item?.rating >= 3.5
                              ? "rgb(243, 153, 22)"
                              : "rgb(246,90,31)",
                        }}
                      >
                        <div
                          className="text-d12 font-bold text-white"
                          style={{ paddingTop: "0.5px" }}
                        >
                          {item?.rating || "0.0"}
                        </div>
                        <AiFillStar className="text-white text-bold text-d11" />
                      </div>
                      <div className="font-light text-d11 pl-0.5">
                        ({item?.nb_of_reviews})
                      </div>
                    </div>
                  )}
                </div> */}

                {/* Add to Cart Button */}
              </div>

              {/* <div className=" flex flex-row  justify-end">
              { isWidget && <div
                              className="relative  w-9 h-9 flex justify-center text-center rounded-md shadow-sm  shadow-dlabelColor bg-white border border-dplaceHolder "
                              onClick={(e) =>
                                addProductToCart(e, item?.product_id, item.name)
                              }
                            >
                                  {loading === item.product_id ? (
                             
                             <div className="relative   z-30 flex items-center justify-center  ">
                             <div className="animate-ping   h-1 w-1.5 bg-dblue2 rounded-full absolute"></div>
                             <div className="animate-ping  h-1.5 w-2 bg-dblue1 rounded-full absolute opacity-50 "></div>
                             <div className="animate-ping  h-2 w-2.5 bg-dblue1 rounded-full absolute  "></div>
                             <div className="animate-ping  h-2.5 w-3 bg-dblue rounded-full absolute"></div>
                             <div className="animate-ping  h-3.5 w-4 bg-dblue1 rounded-full absolute"></div>
                             <div className="animate-ping  h-4.5 w-5 bg-dblue rounded-full absolute"></div>
 
                           </div>
                             ):(
                              <div className=" my-auto">
                                {getProductQuantity(item?.product_id) > 0 && (
                                  <div className="w-4 h-4  bg-dbase1 flex text-white  items-center justify-center rounded-full text-xs absolute right-1 mobile:-right-1.5 -top-1.5 mobile:-top-2.6 border border-white -mr-2 mobile:mr-1">
                                    {getProductQuantity(item?.product_id)}
                                  </div>
                                )}
                                <MdAddShoppingCart  />
                                
                              </div>
                               )}
                              </div>}
                              </div> */}

              {props.addToCart && (
                <button className="w-full bg-dblue text-white h-8 rounded mt-2">
                  Add To Basket
                </button>
              )}
            </div>
          </div>
          {state.admin && (
            <div className="my-1 px-1 flex justify-between z-10">
              {/* <button className=" text-dgrey1">Add To Basket</button> */}
              <span
                className={`p-1  ${copied ? "text-dgreen" : ""}`}
                onClick={(e) => copyContent(e, props.item.sku)}
              >
                {" "}
                {!copied ? props.item.sku : "Copied!"}
              </span>
              {props.item.quantity ? (
                    <span className="bg-dgrey1 bg-opacity-25 p-2 rounded">
                      {props.item.quantity}
                    </span>
                  ) : null}
            </div>
          )}
        </div>
      }
    </Link>
  );
}

export default SingleProducts;
