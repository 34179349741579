import React, { useState } from "react";
import { BsLockFill } from "react-icons/bs";

export const WishListComponent = ({ wishList, product_id, onCheckboxChange }) => {
    const [isChecked, setIsChecked] = useState(() => 
        wishList?.products?.some((product) => product.product_id === parseInt(product_id))
    )
    

  const handleCheckboxToggle = () => {
    setIsChecked(!isChecked);

    
      onCheckboxChange(wishList?.wishlist_group_id);
    
  };

  return (
    <div
      className="flex border p-4 justify-between border-dgreyRate items-center cursor-pointer rounded-md"
      onClick={handleCheckboxToggle}
      key={wishList?.wishlist_group_id}
    >
      <div className="flex">
        <div className="flex flex-col">
          <label className="font-extrabold">{wishList?.name}</label>
          <div className="flex items-center font-light text-[14px] justify-center gap-2 text-dgreyBlack">
            <span className="">
              {wishList?.items_length === "0" ? (
                "No items"
              ) : (
                <>
                  {wishList?.items_length} {wishList?.items_length > 1 ? "items" : "item"}
                </>
              )}
            </span>
            <BsLockFill width={12} height={6} className="font-extrabold" />
          </div>
        </div>
        {wishList?.is_default === "1" && (
          <div className="ml-2 mt-1 bg-dblue text-[12px] text-white px-2 rounded-lg h-fit w-fit">
            Default
          </div>
        )}
      </div>

      <input
        type="checkbox"
        id={`checkbox-${wishList?.wishlist_group_id}`}
        checked={isChecked}
        className="w-4 h-4 text-dgrey"
      />
    </div>
  );
};
