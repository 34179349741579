import { Spinner } from "../components/spinnerLoaderMotion";
import buildLink, { path } from "../urls";
import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BsFilter, BsSearch } from "react-icons/bs";
import { CgEye } from "react-icons/cg";
import { IoReload } from "react-icons/io5";
import { MdOutlineRateReview, MdOutlineReplay } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import _axios from "../axios";
import ThreeDotsWave from "../components/loaderMotion";
import { Button, Tooltip, Typography } from "@material-tailwind/react";
import useDeviceSize from "../components/useDeviceSize";
import { useLocation } from "react-router";
import UserSidebar from "../components/UserSidebar";
import { AccountContext } from "../contexts/AccountContext";
import NoOrders from '../assets/noOrders.png';

function OrdersTest() {
const location=useLocation();
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [loadingReorder, setLoadingReorder] = useState(null);
  const [statuses, setStatuses] = useState([
    { id: "all", name: "All", isActive: true },
    { id: "processing", name: "Processing", isActive: false },
    { id: "complete", name: "Completed", isActive: false },
    { id: "trash", name: "Canceled", isActive: false },
  ]);
  const [state, dispatch] = useContext(AccountContext);
  const navigate = useNavigate();
  const url = new URL(window.location.href); 
  const params = new URLSearchParams(url.search); 
  const page = parseInt(params.get("page"));

  
  // useEffect(() => {
  //   if(!state.loading && !state.loged){
  //     navigate('/')
  //   }
  // }, [state.loged,state.loading]);
  
  const [totalPages, setTotalPages] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(page || 1);


  const [noOrderStatus, setNoOrderStatus] = useState("");
  
  const refinputdate = useRef(null);
  const searchInput = useRef(null);
  

  const status = params.get("status") || "all"; 
  const status_id = status || "all";



  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


 


  useEffect(() => {
    setStatuses((prevStatuses) =>
      prevStatuses.map((status) => ({
        ...status,
        isActive: status.id === status_id,
      }))
    );
    fetchOrders(currentPage);
  }, [status_id, currentPage]);

  const handleStatusClick = (id) => {
    navigate(`?status=${id}&page=1`);
  };

  const fetchOrders = async (currentPage = 1 ) => {
    setLoading(true);
    try {
      const resp = await _axios.get(
        buildLink("orders") +
          `&status=${status_id}&page=${currentPage}&limit=10&filter_order_product_sku=${searchInput.current.value}&filter_date=${refinputdate.current.value}`
      );
      if (resp.data.data.orders) {
        setTotalPages(resp.data.data.meta.total_pages);
        setOrders(resp.data.data.orders);
      } else {
        setOrders([]);
        setTotalPages(0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  

  const reOrder = (order_id) => {
    setLoadingReorder(order_id);

    const obj = {
      order_id,
      source: "website",
    };

    _axios
      .post(buildLink("reorder"), obj)
      .then((response) => {
        if (response.data.success) {
          toast(response.data.message, { type: "success" });
          navigate("/cart");
        } else {
          toast(response.data.message, { type: "error" });
        }
      })
      .catch((error) => {
        toast(error.response.data.message, { type: "error" });
      })
      .finally(() => {
        setLoadingReorder(null);
      });
  };

  const clearInput = () => {
    if (refinputdate.current) {
      refinputdate.current.value = "";
    }
    fetchOrders(page || 1);
  };

  const handlePageClick = (pageselected) => {
    const nextPage = parseInt(pageselected.selected) + 1;
    if (nextPage > 0 && nextPage <= totalPages) {
      setCurrentPage(nextPage);
      navigate({
        pathname: location.pathname,
        search: `?status=${status_id}&page=${nextPage}`,
      });
    }
  };


  
  const getStatusClass = (status) => {
    switch (status) {
      case "Shipped":
        return "text-dgreen bg-dgreen bg-opacity-20 "; 
      case "Pending":
        return "text-dyellow bg-dyellow bg-opacity-20 "; 
      case "Processing":
        return "text-dblue bg-dblue bg-opacity-20 "; 
      case "Cancelled":
        return "text-dbase bg-dbase bg-opacity-20";
      case "Delivered":
        return "text-[#008080] bg-[#008080] bg-opacity-20";
      default:
        return "text-gray-500"; 
    }
  };

  return (
    <div>
      <title>My Account | ishtari</title>

      <div className="flex flex-row">
      <div
        className={`w-full mb-4 md:w-1/5  -ml-3 ${
         !isLargeScreen ? "hidden" : ""
        }`}
      >
            <UserSidebar />
          </div>

          <div className=" w-full h-full  p-5 max-md:py-5 max-md:px-2  flex flex-col gap-3  ">
        <div className="flex flex-col justify-start items-start">
          <h1 className="text-2xl font-bold">My Orders</h1>
        </div>
        <div className="flex flex-col items-center bg-white w-full h-20 rounded-md mx-auto">
          <div className="flex items-center gap-2 w-full max-w-lg my-auto px-5 max-md:px-2">
            {/* Scrollable Container */}

            <div className="flex gap-2 overflow-x-auto scrollbar-hide w-full items-center border-opacity-40 border-4 border-[#e7e7e7] bg-[#e7e7e7] bg-opacity-25 rounded-lg h-fit  ">
              {statuses.map((status) => {
                return (
                  <button
                    onClick={() => handleStatusClick(status.id)}
                    className={`py-2 ${
                      status.isActive ? "bg-white" : "text-dgreySeller"
                    }  font-semibold w-32 text-sm rounded-lg px-2 whitespace-nowrap`}
                  >
                    {status.name}
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        <div className=" flex flex-row bg-white w-full h-20 max-md:h-fit max-md:py-5 rounded-md px-10 max-md:px-2 justify-start ">
          <div className=" w-full max-md:flex-col  h-fit overflow-hidden relative  my-auto flex justify-between gap-2 ">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                fetchOrders(page || 1);
              }}
              className="relative border-opacity-40 border border-[#d5d5d5]  max-md:w-full  w-[40%] bg-[#e7e7e7] bg-opacity-25   rounded-lg flex "
            >
              <input
                placeholder=" Search By Product Name or SKU"
                ref={searchInput}
                className=" placeholder:text-dgreySeller  w-full py-2 pl-2  bg-transparent  outline-none "
              />
              
              <button
                type="submit"
                className="hover:bg-dbase1 h-full  rounded-md  px-4 absolute right-0 top-0 text-xl text-dgrey1 "
              >
                {" "}
                <BsSearch className="text-dgrey1 " />
              </button>
            </form>

            <div className=" flex justify-end max-md:justify-between  gap-2 ">
              {/* <button
                onClick={() => fetchOrders()}
                className="  flex flex-row gap-2 justify-center px-3 border-opacity-40 border border-[#D5D5D5]   bg-[#E7E7E7] bg-opacity-25   rounded-lg "
              >
                <BsFilter className=" my-auto" />
                <span className=" my-auto">Filter</span>
              </button> */}
               <div className="flex flex-row gap-2 justify-center px-3 border-opacity-40 border border-[#D5D5D5] bg-[#E7E7E7] bg-opacity-25 rounded-lg">
      <input
    onChange={() => fetchOrders(page || 1)}
        ref={refinputdate}
        type="date"
        className="border-none outline-none bg-transparent py-2"
      />
     
    </div>
            </div>
          </div>
        </div>
        <div className=" flex flex-row  h-full bg-white rounded-md w-full justify-center mx-auto ">
          {loading ? (
            <div className="py-10">
              <ThreeDotsWave />
            </div>
          ) : (
            <div className="container h-full mx-auto px-4 py-4 max-md:px-2 ">
              {isLargeScreen ? (
                <div className=" overflow-x-auto h-full">
                  <div className=" w-full h-full ">
                    {orders.length > 0  ? (
                      <table className="w-full">
                        <thead className=" text-dgrey1 border-b border-dplaceHolder bg-gray-100">
                          <tr>
                            <th className="px-6 py-3 text-center">Orders</th>
                            <th className="px-6 py-3 text-center">Date</th>
                            <th className="px-6 py-3 text-center">Products</th>
                            <th className="px-6 py-3 text-center">Payment</th>
                            <th className="px-6 py-3 text-center">Status</th>
                            <th className="px-6 py-3 text-center">Price</th>
                            <th className="px-6 py-3 text-center text-dblack pr-semibold">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders?.map((order, index) => (
                            <tr
                              key={index}
                              className="border-b border-dplaceHolder"
                            >
                              <td className="px-6 py-4 text-center">
                                <div className=" flex flex-col gap-1">
                                  <span className=" text-dgreyProduct text-sm">
                                    #{order.order_id}
                                  </span>
                                  <span>{order.region}</span>
                                </div>
                              </td>
                              <td className="px-6 py-4 text-center text-dgreyQtyProduct text-sm">
                                {order.date_added}
                              </td>
                              <td className="px-6 py-4 text-center">
                                {order.products}
                              </td>
                              <td className="px-6 py-4 text-center">
                                <div className=" flex flex-col gap-2 justify-center text-center">
                                  <div className=" w-8 mx-auto">
                                    <img src={order.payment_method_image} />
                                  </div>
                                  <h2 className=" text-dblack text-sm">
                                    {order.payment_method}
                                  </h2>{" "}
                                </div>
                              </td>
                              <td className={`px-2 py-4 text-center `}>
                                {" "}
                                <span
                                  className={`${getStatusClass(
                                    order.status
                                  )} px-2 rounded-lg text-sm py-2`}
                                >
                                  {order.status}
                                </span>
                              </td>{" "}
                              {/* Dynamically styled status */}
                              <td className="px-6 py-4 text-center">
                                {order.total}
                              </td>
                              <td className=" ">
                                <div className=" flex justify-center gap-2">
                                  <Link
                                    to={`/account/order/${order.order_id}`}
                                  >
                                      <Tooltip
                              placement="bottom"
                              className="border  bg-white px-4 py-1.5 shadow-xl "
                              animate={{
                                mount: { scale: 1, y: 0 },
                                unmount: { scale: 0, y: 25 },
                              }}
                              content={
                                <div className="w-20 text-center" style={{color:"red"}}>
                                  View Details
                                </div>
                              }
                            >
                              <Link
                                className=""
                                to={`/account/order/${order.order_id}`}
                              >
                                <svg
                                  width="25"
                                  height="25"
                                  viewBox="0 0 24 24"
                                  className="mr-1"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M21.2572 10.9622C21.7314 11.5813 21.7314 12.4187 21.2572 13.0378C19.764 14.9868 16.1818 19 12 19C7.81823 19 4.23598 14.9868 2.74284 13.0378C2.26857 12.4187 2.26856 11.5813 2.74283 10.9622C4.23598 9.01321 7.81823 5 12 5C16.1818 5 19.764 9.01321 21.2572 10.9622Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="3"
                                    stroke="black"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                <span className=""></span>
                              </Link>
                            </Tooltip>
                                     
                                  </Link>
                                  
                                  {loadingReorder == order.order_id ? (
                                    <div className="  w-4 h-4">
                                      <Spinner />
                                    </div>
                                  ) : (
                                    <Tooltip
                              placement="bottom"
                              className="border  bg-white px-4 py-1.5 shadow-xl "
                              animate={{
                                mount: { scale: 1, y: 0 },
                                unmount: { scale: 0, y: 25 },
                              }}
                              content={
                                <div className="w-36 text-dblackk">
                                  <Typography className="font-medium " style={{color:"red"}}>
                                    Reorder
                                  </Typography>
                                  <Typography
                                    variant="small"
                                    className="font-normal text-d10 opacity-80"
                                  >
                                    Make the same order.
                                  </Typography>
                                </div>
                              }
                            >
                              <button
                                onClick={() => reOrder(order.order_id)}
                                className=""
                              >
                                <MdOutlineReplay className="mr-1 w-6 h-6" />
                                <span></span>
                              </button>
                            </Tooltip>

                                  )}
                                  {order.status === "Processing" && (
                                    <Link
                                      href={`${path}/account/order-reviews/${order.order_id}`}
                                      className=""
                                    >
                                      <MdOutlineRateReview className=" my-auto" />
                                    </Link>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className=" w-full bg-white my-auto gap-5 flex-col  h-full  flex justify-center  ">
                        <div className="   flex justify-center ">
                          <img
                            className=" my-auto"
                            src={NoOrders}
                          />
                        </div>
                        <h2 className=" text-center text-2xl font-bold">
                          No Orders available
                        </h2>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="space-y-4">
                {orders.length > 0  ? (
                  orders.map((order) => (
                    <div
                      key={order.order_id}
                      className={`border flex flex-row justify-between border-[#d5d5d5] border-opacity-50 rounded-lg p-4`}
                    >
                      <div className="flex flex-col gap-1">
                        <h2 className="text-dblack text-lg">Order # {order.order_id}</h2>
                        <p className="text-dgrey1 text-sm">Date: {order.date_added}</p>
                        <p className="text-dgrey1 text-sm">Products: {order.products}</p>
                        <p className="text-dgrey1 text-sm">Payment Method: {order.payment_method}</p>
                        <p className="text-dgrey1 py-2 text-sm">
                          <span
                            className={`${getStatusClass(order.status)} px-2 rounded-lg text-sm py-2`}
                          >
                            {order.status}
                          </span>
                        </p>
                      </div>
                      <div className="flex flex-col justify-between">
                        <p className="text-dgrey1 text-sm">Total: {order.total}</p>
                        <div className="flex justify-center gap-3">
                          <Link to={`/account/order/${order.order_id}`}>
                            <Tooltip
                              placement="bottom"
                              className="border bg-white px-4 py-1.5 shadow-xl"
                              animate={{
                                mount: { scale: 1, y: 0 },
                                unmount: { scale: 0, y: 25 },
                              }}
                              content={<div className="w-20 text-center" style={{ color: "red" }}>View Details</div>}
                            >
                              <svg
                                width="25"
                                height="25"
                                viewBox="0 0 24 24"
                                className="mr-1"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21.2572 10.9622C21.7314 11.5813 21.7314 12.4187 21.2572 13.0378C19.764 14.9868 16.1818 19 12 19C7.81823 19 4.23598 14.9868 2.74284 13.0378C2.26857 12.4187 2.26856 11.5813 2.74283 10.9622C4.23598 9.01321 7.81823 5 12 5C16.1818 5 19.764 9.01321 21.2572 10.9622Z"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <circle
                                  cx="12"
                                  cy="12"
                                  r="3"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Tooltip>
                          </Link>
                          {loadingReorder == order.order_id ? (
                            <div className="w-4 h-4">
                              <Spinner />
                            </div>
                          ) : (
                            <Tooltip
                              placement="bottom"
                              className="border bg-white px-4 py-1.5 shadow-xl"
                              animate={{
                                mount: { scale: 1, y: 0 },
                                unmount: { scale: 0, y: 25 },
                              }}
                              content={
                                <div className="w-36 text-dblackk">
                                  <Typography className="font-medium" style={{ color: "red" }}>Reorder</Typography>
                                  <Typography variant="small" className="font-normal text-d10 opacity-80">
                                    Make the same order.
                                  </Typography>
                                </div>
                              }
                            >
                              <button onClick={() => reOrder(order.order_id)} className="">
                                <MdOutlineReplay className="mr-1 w-6 h-6" />
                              </button>
                            </Tooltip>
                          )}
                          {order.status === "Complete" && (
                            <Tooltip
                              placement="bottom"
                              className="border bg-white px-4 py-1.5 shadow-xl"
                              animate={{
                                mount: { scale: 1, y: 0 },
                                unmount: { scale: 0, y: 25 },
                              }}
                              content={
                                <div className="w-40 text-dblackk">
                                  <Typography className="font-medium" style={{ color: "red" }}>Review</Typography>
                                  <Typography variant="small" className="font-normal text-d10 opacity-80">
                                    Write a review for the products in this order.
                                  </Typography>
                                </div>
                              }
                            >
                              <Link href={`/account/order/${order.order_id}`}>
                                <svg
                                  width="23"
                                  height="23"
                                  fill="currentColor"
                                  className="mr-1 text-dyellow"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                                </svg>
                              </Link>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-full bg-white my-auto gap-5 flex-col h-full flex justify-center">
                    <div className="flex justify-center">
                      <img className="my-auto" src={NoOrders} />
                    </div>
                    <h2 className="text-center text-2xl font-bold">No Orders available</h2>
                  </div>
                )}
              </div>
              
              )}

              { orders.length > 0 && totalPages > 1 &&  (
                <div className="h-12 my-5">
                  <ReactPaginate
                    className={"category-pagination"}
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={!isLargeScreen ? 1 : 2}
                    marginPagesDisplayed={!isLargeScreen ? 1 : 2}
                    pageCount={totalPages}
                    previousLabel="<"
                    activeClassName={"active-pagination-category"}
                    renderOnZeroPageCount={null}
                    forcePage={
                      Number(currentPage) > 0 ? Number(currentPage) - 1 : 0
                    }
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      </div>
    </div>
  );
}

export default OrdersTest;
