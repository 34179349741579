import React, { useEffect, useState } from "react";
import useDeviceSize from "../components/useDeviceSize";
import buildLink from "../urls";
import { useNavigate } from "react-router-dom";
import _axios from "../axios";
import DOMPurify from "dompurify";
import App from "../assets/images/app.png";
import Web from "../assets/images/web.png";

export const Hc = () => {
  const [topics, setTopics] = useState(null);
  const [width] = useDeviceSize();
  const navigate = useNavigate();

  useEffect(() => {
    _axios
      .get(buildLink("getTopics", undefined, window.innerWidth))
      .then((response) => {
        setTopics(response.data.data);
      });
  }, []);

  return (
    <div className=" sm:container sm:mx-auto w-full sm:px-12">
      {width > 650 ? (
        <img className="  w-full" src={Web} loading="lazy" />
      ) : (
        <img className=" w-full" src={App} loading="lazy" />
      )}
      <div className="container grid grid-cols-2 max-md:grid-cols-1 gap-5 my-10 md:px-12 align-middle text-center ">
        {topics &&
          topics.map(
            (topic) =>
              topic.inquiry_count > 0 &&
              topic.status == 1 && (
                <button
                  onClick={() => navigate(`${topic.id}`)}
                  className=" flex  flex-row max-md:flex-col justify-start  text-center gap-4 w-full  p-6 bg-white  text-dgrey1 rounded-lg  hover:shadow-lg shadow-md transition-all  "
                >
                  <img className=" w-24" src={topic.image} loading="lazy" />
                  <div className=" flex w-full flex-col justify-start text-start gap-2 my-auto">
                    <h5
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(topic.title),
                      }}
                      className="mb-2 text-2xl font-semibold tracking-tight  text-dblack"
                    ></h5>

                    <p className="mb-3 font-normal  text-dgrey1">
                      {topic.description}
                    </p>

                    <span className=" text-dgreyAddress text-xs  border-t border-dgreyAddress  border-dashed pt-1">
                      {topic.inquiry_count} article
                    </span>
                  </div>
                </button>
              )
          )}
      </div>
    </div>
  );
};
