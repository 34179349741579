var host = window.location.host;
export var path = "";

if (window.location.href.split("/")[3] === "bey") {
  path = "/bey";
}

if (window.location.href.split("/")[3] === "zahle-store") {
  path = "/zahle-store";
}

export var pixelID = "";
var mobileurls;
var path1 = "";
var urls = {
  dynamicproducts: "v2/index.php?route=catalog/product/products&params=",
  getAllTopSellingbyCategoryid:
    "v2/index.php?route=cronjob/sold_item/getAllTopSellingbyCategoryid",
  token: "v2/index.php?route=token/token&grant_type=client_credentials",
  login: "v2/index.php?route=account/login/login",
  get_account: "v2/index.php?route=account/account/getAccount",
  save_account: "v2/index.php?route=account/account/saveAccount",
  change_password: "v2/index.php?route=account/account/changePassword",
  forget_password: "v2/index.php?route=account/forgotten/forgotten",
  wishlist: "v2/index.php?route=account/wishlist/wishlist",
  wishlistCount: "v2/index.php?route=account/wishlist/getTotalWishlist",
  orders: "v2/index.php?route=account/order/ordersNew&source_id=1",
  order_details: "v2/index.php?route=account/order/orders&id=",
  register: "v2/index.php?route=account/register/register",
  latest: "v2/index.php?route=catalog/product/latest",
  zone: "v2/index.php?route=account/zone&country_id=",
  town: "v2/index.php?route=account/town&zone_id=",
  social: "v2/index.php?route=account/login/sociallogin",
  logout: "v2/index.php?route=account/logout/logout",
  address: "v2/index.php?route=account/address/address",
  cart: "v2/index.php?route=checkout/cart/cart",
  cartCount: "v2/index.php?route=checkout/cart/getProductsCount",
  search: "v2/index.php?route=catalog/search/autoCompleteV2&term=",
  clearCache: "v2/index.php?route=catalog/search/clearKeyCache&term=",
  all_categories: "v2/index.php?route=catalog/category/categories&level=2",
  home: "v2/index.php?route=common/widgets",
  //home: "v2/index.php?route=common/widgets_preview",
  product: "v2/index.php?route=catalog/product&product_id=",
  recentlyViewed: "v2/index.php?route=catalog/recently_viewed",
  productpreview: "v2/index.php?route=catalog/product_preview&product_id=",
  category: "v2/index.php?route=catalog/category&path=",
  manufacturer: "v2/index.php?route=catalog/manufacturer&manufacturer_id=",
  seller: "v2/index.php?route=catalog/seller&seller_id=",
  alg: "v2/index.php?route=catalog/search&key=",
  filter: "v2/index.php?route=catalog/filter_product",
  banner_event: "v2/index.php?route=design/bannerevent",
  menu: "v2/index.php?route=common/home/getMenu",
  footer: "v2/index.php?route=common/home/getFooter",
  // all_categories:"v2/index.php?route=catalog/category/categories&level=2",
  manual: "v2/index.php?route=checkout/manual",
  viewCatalogSortProducts:
    "v2/index.php?route=catalog/product_preview/viewCatalogSortProducts",
  getProductAdditionalData:
    "v2/index.php?route=catalog/product/getProductAdditionalData",
  payment_form: "v2/index.php?route=checkout/payment_form",
  autoCompletePhone:
    "v2/index.php?route=account/address/autoComplete&filter_name=",
  getCustomerByPhone:
    "v2/index.php?route=account/address/getCustomerByPhone&phone=",
  information: "v2/index.php?route=catalog/information",
  alias: "v2/index.php?route=catalog/seo/handler&keyword=",
  footerv2: "v2/index.php?route=common/footerItem",
  headerv2: "v2/index.php?route=design/headerMenu",
  reviews: "v2/index.php?route=catalog/review",
  pixel: "v2/index.php?route=marketing/st",
  currency: "v2/index.php?route=account/change/currency",
  notify: "v2/index.php?route=marketing/notify/addNotification",
  likeUnlikeReview:
    "v2/index.php?route=catalog/product/likeUnlikeReview&review_id=",
  reviewsreply: "v2/index.php?route=catalog/review/reviewReply",

  // productBundles:
  //   "v2/index.php?route=catalog/product/getProductBundles&product_id=",
  insertLike: "v2/index.php?route=catalog/product/likeProduct",
  deleteLike: "v2/index.php?route=catalog/product/unlikeProduct",
  getLikeProduct: "v2/index.php?route=catalog/product/getLikedProducts",
  verify: "v2/index.php?route=account/address/addressPhoneVerification",
  checkVerify: "v2/index.php?route=account/address/checkVerification",
  disabledAccount: "v2/index.php?route=account/logout/disableAccount",
  widgetsPreview: "v2/index.php?route=common/widgets_preview",
  buyagain: "v2/index.php?route=account/order/getProductFromCompletedOrder",
  contactUs: "v2/index.php?route=account/contact_us",
  wishlist_group: "v2/index.php?route=account/wishlist_group/getGroups",
  EmailNotifications:
    "v2/index.php?route=account/account/getCustomerEmailDisable",
  disableEmailNotification:
    "v2/index.php?route=account/account/customerEmailDisable",
  whishlistProducts:
    "v2/index.php?route=account/wishlist_group/getGroupProducts",
  wishlistAdd: "v2/index.php?route=account/wishlist_group/addGroup",
  wishlistDelete:
    "v2/index.php?route=account/wishlist_group/deleteGroup&group_id=",
  wishlistUpdate: "v2/index.php?route=account/wishlist_group/updateGroup",
  addToWishlist_5: "v2/index.php?route=account/wishlist_group/addToWishlist",
  removeAll: "v2/index.php?route=account/wishlist_group/deleteItemFromAllGroup",
  pos: "v2/index.php?route=checkout/cart/addToCartPos",
  getSalesMan: "v2/index.php?route=stockapi/admin_login/getSalesMan",
  searchProduct: "v2/index.php?route=stockapi/product&item=",
  coupons: "v2/index.php?route=account/coupons",
  redeemCoupon: "v2/index.php?route=account/coupons/redeemCoupon",
  checkin: "v2/index.php?route=account/points/checkin",
  sharedCart: "v2/index.php?route=checkout/cart/shareCart",
  getSharedCart: "v2/index.php?route=checkout/cart/getShareCartProducts",
  addShareCart: "v2/index.php?route=account/order/share_cart",
  switchAccount: "v2/index.php?route=account/account/switchAccount",
  memberShip: "v2/index.php?route=account/points/getPointData",
  getRecommandedPoints:
    "v2/index.php?route=account/points/getRecommendedProducts",
  reorder: "v2/index.php?route=account/order/bulk_add_to_cart",
  getBalance: "v2/index.php?route=account/wallet/getBalance",
  getTransactionHistory:
    "v2/index.php?route=account/wallet/getTransactionHistory",
  reviewedProtuctsCenter:
    "v2/index.php?route=catalog/review/getCustomerReviewedProducts",
  unreviewedProtuctsCenter:
    "v2/index.php?route=catalog/review/getCustomerUnreviewedProducts",
  getcustomerfeedback:
    "v2/index.php?route=account/feedback/getCustomerFeedback",
  editcustomerfeedback:
    "v2/index.php?route=account/feedback/editCustomerFeedback",
  suggestion: "v2/index.php?route=account/account/suggestion",
  similarWishlistProducts: "v2/index.php?route=account/wishlist/recommendation",
  createOTP: "v2/index.php?route=account/login/createOTP",
  newLogin: "v2/index.php?route=account/login/login_new&source_id=1",
  loginByOTP: "v2/index.php?route=account/login/loginByOtp",
  getOTPForetPassword: "v2/index.php?route=account/forgotten/forgotPassword",
  validateOTPForgetPassword: "v2/index.php?route=account/forgotten/validateOTP",
  resetPassword: "v2/index.php?route=account/forgotten/resetPassword",
  clearGroupWishList:
    "v2/index.php?route=account/wishlist_group/clearGroupWishList",
  claimPoints: "v2/index.php?route=account/points/claimPoints",
  resendOTP: "v2/index.php?route=account/forgotten/resendOTP",
  previewTempSortCatalogProducts:
    "v2/index.php?route=catalog/product_preview/previewTempSortCatalogProducts",
  sendRestoreId: "v2/index.php?route=account/account/saveChatId",
  getTopics: "v2/index.php?route=catalog/ishtari_documentation/getTopics",
  addFeedback: "v2/index.php?route=catalog/ishtari_documentation/addFeedback",
  getInquiry: "v2/index.php?route=catalog/ishtari_documentation/getInquiries",
  verifyTelephoneCode:
    "v2/index.php?route=account/register/verifyTelephoneCode",
  follow: "v2/index.php?route=account/account/follow",
  CustomerPhoneVerification:
    "v2/index.php?route=account/account/CustomerPhoneVerification",
  OrderReviews: "v2/index.php?route=catalog/order_review",
  getsavedemails:
    "v2/index.php?route=account/wallet/getSavedTransferReceiverEmails",
  transferwalletcredit:
    "v2/index.php?route=account/wallet/transferWalletCredit",

  claimpoints: "v2/index.php?route=account/points/claimPoints",
};
if (
  localStorage.getItem("site-local-name") === "ishtari-ghana" ||
  host === "www.ishtari.com.gh" ||
  host === "ishtari.com.gh" ||
  host.startsWith("localhost")
) {
  pixelID = "590578733014699";
  host = "https://www.ishtari.com.gh/";
  // urls = {
  path1 = "";
  //   token: "v2/index.php?route=token/new_token&grant_type=client_credentials",
  //   login: "v2/index.php?route=account/login/login",
  //   get_account: "v2/index.php?route=account/account/getAccount",
  //   save_account: "v2/index.php?route=account/account/saveAccount",
  //   change_password: "v2/index.php?route=account/account/changePassword",
  //   wishlist: "v2/index.php?route=account/wishlist/wishlist",
  //   orders: "v2/index.php?route=account/order/orders",
  //   order_details: "v2/index.php?route=account/order/orders&id=",
  //   register: "v2/index.php?route=account/register/register",
  //   latest: "v2/index.php?route=catalog/product/latest",
  //   zone: "v2/index.php?route=account/zone/zone&country_id=",
  //   town: "v2/index.php?route=account/town/town&zone_id=",
  //   social: "v2/index.php?route=account/login/sociallogin",
  //   logout: "v2/index.php?route=account/logout/logout",
  //   address: "v2/index.php?route=account/address/address",
  //   cart: "v2/index.php?route=checkout/cart/cart",
  //   cartCount: "v2/index.php?route=checkout/cart/getProductsCount",
  //   search: "v2/index.php?route=catalog/fastsearch/autoComplete&term=",
  //   home: "v2/index.php?route=common/widgets",
  //   product: "v2/index.php?route=catalog/product&product_id=",
  //   productpreview: "v2/index.php?route=catalog/productpreview&product_id=",
  //   category: "v2/index.php?route=catalog/category&path=",
  //   manufacturer: "v2/index.php?route=catalog/manufacturer&manufacturer_id=",
  //   seller: "v2/index.php?route=catalog/seller&seller_id=",
  //   alg: "v2/index.php?route=catalog/new_search&key=",
  //   filter: "/v2/index.php?route=catalog/filter_product/new_filter",
  //   banner_event: "v2/index.php?route=design/bannerevent",
  //   menu: "v2/index.php?route=common/home/getMenu",
  //   footer: "v2/index.php?route=common/home/getFooter",
  //   all_categories: "v2/index.php?route=catalog/category/categories&level=2",
  //   manual: "v2/index.php?route=checkout/manual",
  //   payment_form: "v2/index.php?route=checkout/payment_form",
  //   autoCompletePhone:
  //     "v2/index.php?route=account/address/autoComplete&filter_name=",
  //   getCustomerByPhone:
  //     "v2/index.php?route=account/address/getCustomerByPhone&phone=",
  //   information: "v2/index.php?route=catalog/information",
  //   footerv2: "v2/index.php?route=common/footerItem",
  //   headerv2: "v2/index.php?route=design/headermenu/header",
  //   reviews: "v2/index.php?route=catalog/review"
  // };
}

if (
  localStorage.getItem("site-local-name") === "ishtari" ||
  host === "www.ishtari.com" ||
  host === "www.sari3.com" ||
  host === "ishtari.com"
) {
  pixelID = "668318187192045";
  host = "https://www.ishtari.com/";
  path1 = "motor/";
  mobileurls = {
    token: "v2/index.php?route=token/token&grant_type=client_credentials",
    login: "v2/index.php?route=account/login/login",
    get_account: "v2/index.php?route=account/account/getAccount",
    save_account: "v2/index.php?route=account/account/saveAccount",
    change_password: "v2/index.php?route=account/account/changePassword",
    forget_password: "v2/index.php?route=account/forgotten/forgotten",
    wishlist: "v2/index.php?route=account/wishlist/wishlist",
    wishlistCount: "v2/index.php?route=account/wishlist/getTotalWishlist",
    orders: "v2/index.php?route=account/order/orders",
    order_details: "v2/index.php?route=account/order/orders&id=",
    register: "v2/index.php?route=account/register/register",
    latest: "v2/index.php?route=catalog/product/latest",
    zone: "v2/index.php?route=account/zone&country_id=",
    social: "v2/index.php?route=account/login/sociallogin",
    logout: "v2/index.php?route=account/logout/logout",
    address: "v2/index.php?route=account/address/address",
    cart: "v2/index.php?route=checkout/cart/cart",
    cartCount: "v2/index.php?route=checkout/cart/getProductsCount",
    search: "v2/index.php?route=catalog/search/autoCompleteV2&term=",
    home: "v2/index.php?route=common/widgets",
    //home: "v2/index.php?route=common/widgets_preview",
    product: "v2/index.php?route=catalog/product&product_id=",
    productpreview: "v2/index.php?route=catalog/product_preview&product_id=",
    category: "v2/index.php?route=catalog/category&path=",
    manufacturer: "v2/index.php?route=catalog/manufacturer&manufacturer_id=",
    seller: "v2/index.php?route=catalog/seller&seller_id=",
    alg: "v2/index.php?route=catalog/search&key=",
    filter: "v2/index.php?route=catalog/filter_product",
    banner_event: "v2/index.php?route=design/bannerevent",
    menu: "v2/index.php?route=common/home/getMenu",
    footer: "v2/index.php?route=common/home/getFooter",
    all_categories: "v2/index.php?route=catalog/category/categories&level=2",
    manual: "v2/index.php?route=checkout/manual",
    payment_form: "v2/index.php?route=checkout/payment_form",
    autoCompletePhone:
      "v2/index.php?route=account/address/autoComplete&filter_name=",
    getCustomerByPhone:
      "v2/index.php?route=account/address/getCustomerByPhone&phone=",
    information: "v2/index.php?route=catalog/information",
    alias: "v2/index.php?route=catalog/seo/handler&keyword=",
    footerv2: "v2/index.php?route=common/footerItem",
    headerv2: "v2/index.php?route=design/headerMenu",

    currency: "v2/index.php?route=account/change/currency",
    notify: "v2/index.php?route=marketing/notify/addNotification",
    // productBundles:
    //   "v2/index.php?route=catalog/product/getProductBundles&product_id=",
    insertLike: "v2/index.php?route=catalog/product/likeProduct",
    deleteLike: "v2/index.php?route=catalog/product/unlikeProduct",
    getLikeProduct: "v2/index.php?route=catalog/product/getLikedProducts",
    town: "v2/index.php?route=account/town/town&zone_id=",
    verify: "v2/index.php?route=account/address/addressPhoneVerification",
    checkVerify: "v2/index.php?route=account/address/checkVerification",
    disabledAccount: "v2/index.php?route=account/logout/disableAccount",
    widgetsPreview: "v2/index.php?route=common/widgets_preview",
    buyagain: "v2/index.php?route=account/order/getProductFromCompletedOrder",
    contactUs: "v2/index.php?route=account/contact_us",
    EmailNotifications:
      "v2/index.php?route=account/account/getCustomerEmailDisable",
    disableEmailNotification:
      "v2/index.php?route=account/account/customerEmailDisable",
  };
}
const firstPath = window.location.href.split("/")[3];
console.log(
  `before initial store condition item in localstorage ${localStorage.getItem(
    "site-local-name"
  )}... host is ${host}.... first path is ${firstPath}`
);
if (
  localStorage.getItem("site-local-name") === "flo" ||
  ((host === "www.flo-lebanon.com" || host === "flo-lebanon.com") &&
    firstPath !== "bey" &&
    firstPath !== "zahle-store")
) {
  host = "https://www.flo-lebanon.com/";
  path1 = "api/";
}
console.log(
  `before bey condition item in localstorage ${localStorage.getItem(
    "site-local-name"
  )}... host is ${host}.... first path is ${firstPath}`
);
if (
  localStorage.getItem("site-local-name") === "flo-bey" ||
  ((host === "www.flo-lebanon.com" || host === "flo-lebanon.com") &&
    firstPath === "bey")
) {
  host = "https://www.flo-lebanon.com/";
  path1 = "bey/api/";
}
console.log(
  `before zahle condition item in localstorage ${localStorage.getItem(
    "site-local-name"
  )}... host is ${host}.... first path is ${firstPath}`
);
if (
  localStorage.getItem("site-local-name") === "flo-zahle" ||
  ((host === "www.flo-lebanon.com" || host === "flo-lebanon.com") &&
    firstPath === "zahle-store")
) {
  host = "https://www.flo-lebanon.com/";
  path1 = "zahle/api/";
}
if (
  localStorage.getItem("site-local-name") === "aalbeit" ||
  host === "www.aalbeit.com" ||
  host === "aalbeit.com"
) {
  host = "https://www.aalbeit.com/";
  path1 = "api/";
}
if (
  localStorage.getItem("site-local-name") === "ishtari-usd" ||
  host === "www.ishtari-usd.com"
) {
  host = "https://www.ishtari-usd.com/";
  path1 = "api/";
}
if (
  localStorage.getItem("site-local-name") === "energy-plus" ||
  host === "www.energyplus-lb.com" ||
  host === "energyplus-lb.com"
) {
  host = "https://energyplus-lb.com/";
  path1 = "api/";
}
function buildLink(link, payload, width) {
  const extra_params = typeof payload == "undefined" ? "" : payload;
  return `${host}` + path1 + urls[link] + extra_params;
  // }
}
export default buildLink;
