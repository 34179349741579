import { useContext } from "react";
import _axios from "../axios";
import { AccountContext } from "../contexts/AccountContext";
import UserSidebar from "../components/UserSidebar";
import UserSidebarMobile from "../components/UserSidebarMobile";
import PointsLoader from "../components/PointsLoader";
import SingleProduct from "../components/SingleProduct";
import useDeviceSize from "../components/useDeviceSize";
import { useReviewCenterData } from "../contexts/ReviewCenterContext";
import buildLink from "../urls";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { BsClockFill, BsStarFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function ReviewCenter() {
  const [state, dispatch] = useContext(AccountContext);
  const [width] = useDeviceSize();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [awaitingReview, setAwaitingReview] = useState(true);
  const { reviewCenterData, setReviewCenterData } = useReviewCenterData();
  const navigate = useNavigate();
  useEffect(() => {
    if (!state.loading && !state.loged) {
      navigate("/");
    } else if (state.loged) {
      setLoading(true);
      if (awaitingReview) {
        _axios
          .get(
            buildLink("unreviewedProtuctsCenter", undefined, window.innerWidth)
          )
          .then((response) => {
            setLoading(false);
            const data = response.data.data;
            setData(data);
            if (!state.loged) {
              navigate("/");
            }
          });
      } else {
        _axios
          .get(
            buildLink("reviewedProtuctsCenter", undefined, window.innerWidth)
          )
          .then((response) => {
            setLoading(false);
            const data = response.data.data;
            setData(data);
            if (!state.loged) {
              navigate("/");
            }
          });
      }
    }
  }, [awaitingReview, state.loading]);

  const handleClick = (product_id) => {
    setReviewCenterData({
      product_id: product_id,
      scrollToReview: true,
    });
  };

  return (
    <div className="container text-dblack">
      <Helmet>
        <title>My Account | ishtari</title>
      </Helmet>
      <div className="pb-2">
        <div className="flex-row md:flex">
          <div className="w-full mb-3 md:w-1/5 -ml-3">
            {/* {width > 650 ? (
              <UserSidebar active={"reviewCenter"} />
            ) : (
              <UserSidebarMobile active={"reviewCenter"} />
            )} */}
            {width > 650 && <UserSidebar active={"reviewCenter"} />}
          </div>

          <div className="w-full px-2 md:pl-6 my-5">
            <h1 className="text-lg pr-semibold">Review Center </h1>
            <div className="mt-8 tabs">
              <div className="w-full flex items-center gap-8">
                <div
                  className={`flex items-center gap-3 cursor-pointer pb-1.5 border-b-2 ${
                    awaitingReview ? "text-dblue" : "border-transparent"
                  }`}
                  onClick={() => setAwaitingReview(true)}
                >
                  <p>Awaiting Review</p>
                  <BsClockFill />
                </div>
                <div
                  className={`flex items-center gap-3 cursor-pointer pb-1.5 border-b-2 ${
                    !awaitingReview ? "text-dblue" : "border-transparent"
                  }`}
                  onClick={() => setAwaitingReview(false)}
                >
                  <p>Reviewed</p>
                  <BsStarFill />
                </div>
              </div>
            </div>

            {loading ? (
              <div className="flex justify-center w-full">
                <PointsLoader />
              </div>
            ) : (
              <div className="grid grid-cols-2 lg:grid-cols-6 gap-2 pt-4">
                {data?.products &&
                  data.products.map((item) => (
                    <div
                      key={item.product_id}
                      onClick={() => handleClick(item.product_id)}
                    >
                      <SingleProduct item={item} />
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewCenter;
