import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccountContext } from "../contexts/AccountContext";
import { CurrencyContext } from "../contexts/CurrencyContext";
import { useMessage } from "../contexts/MessageContext";
import buildLink from "../urls";
import { HandleAuthForm } from "../components/HandleAuthForm";
import {
  FaAngleDoubleDown,
  FaAngleLeft,
  FaCaretDown,
  FaDotCircle,
  FaMinus,
  FaPlus,
  FaThumbsDown,
  FaThumbsUp,
} from "react-icons/fa";
import _axios from "../axios";
import App from "../assets/images/app.png";
import Web from "../assets/images/web.png";

export const HcItem = () => {
  const id = useParams().id;
  const [data, setData] = useState(null);
  const [activeInquiri, setActiveInquiri] = useState(null);
  const [title, setTitle] = useState(null);
  const [titleImage, setTitleImage] = useState(null);
  const { openAuthForm } = HandleAuthForm();
  const [stateCom, dispathcCom] = useContext(AccountContext);
  const dataInformation = useContext(CurrencyContext);
  const { setGlobalMessage, setSuccessMessage, setErrorMessage } = useMessage();
  const navigate = useNavigate();

  const decodeHtmlEntities = (html) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;

    // Replace <li> with <ol>
    const olRegex = /<li>(.*?)<\/li>/g;
    let match;
    let index = 1;
    while ((match = olRegex.exec(txt.value)) !== null) {
      const listItem = match[1];
      const olItem = `<li>${index}. ${listItem}</li>`;
      txt.value = txt.value.replace(match[0], olItem);
      index++;
    }

    return txt.value;
  };

  const HtmlDecoder = ({ encodedHtml }) => {
    const decodedHtml = decodeHtmlEntities(encodedHtml);

    return <div dangerouslySetInnerHTML={{ __html: decodedHtml }} />;
  };

  async function addFeedback(id, inquiryRatingId) {
    if (stateCom.loged) {
      const comment = document.getElementById(`comment${id}`);

      const obj = {
        inquiry_rating_id: inquiryRatingId,
        inquiry_id: id,
        comment: comment?.value,
      };
      await _axios.post(buildLink("addFeedback"), obj).then((res) => {
        if (res.data.success) {
          setSuccessMessage(true);
          setGlobalMessage(res.data.message);
          getInquiries();
        } else {
          getInquiries();
        }
      });
    } else {
      openAuthForm();
    }
  }

  async function getInquiries() {
    await _axios
      .get(buildLink("getInquiry") + "&topic_id=" + id)
      .then((res) => {
        setData(res.data.data);
        setTitle(res.data.topic_title);
        setTitleImage(res.data.topic_image);
        console.log("new data: " + JSON.stringify(res.data));
      });
  }

  useEffect(() => {
    if (id) {
      getInquiries();
    }
  }, [id]);

  return (
    <div className="w-full sm:container">
      <div>
        <img src={Web} alt="web" className="  max-md:hidden  w-full" />
        <img src={App} alt="app" className="  md:hidden  w-full" />
      </div>
      <div className="flex max-md:flex-col w-full max-md:h-full md:h-[40vh] gap-x-10 gap-y-5 container mt-10">
        {/*Left Section  */}
        <div className=" md:w-[30%] w-full   flex flex-col gap-5 justify-start text-left  md:border-r border-dplaceHolder">
          <button
            onClick={() => navigate(-1)}
            className=" w-fit px-2 rounded-md py-1 hover:bg-dplaceHolder flex flex-row gap-3 justify-start  text-start"
          >
            <FaAngleLeft className=" my-auto" />
            <span>Back</span>
          </button>

          <div className="    h-full relative   max-md:flex-col max-md:gap-1 flex flex-row justify-start gap-5">
            {titleImage && (
              <img
                className=" max-md:hidden  w-32 h-38 my-auto "
                src={titleImage}
                alt="Title Image"
              />
            )}
            <h2 className="font-bold text-3xl text-black uppercase my-auto">
              {title}
            </h2>
          </div>
        </div>

        {/*Right Section  */}
        <div className="flex flex-col md:grid  max-md:w-full w-[70%]  overflow-y-auto   md:grid-cols-2 lg:grid-cols-2 gap-3 ">
          {data &&
            data.map((item) => (
              <div
                key={item.id}
                className={`w-full ${
                  activeInquiri == item.id && " border "
                } border-dlabelColor rounded-md`}
              >
                <button
                  onClick={() =>
                    setActiveInquiri((prev) =>
                      prev === item.id ? null : item.id
                    )
                  }
                  className={`relative  justify-between ${
                    activeInquiri == item.id && " bg-dplaceHolder"
                  }  w-full flex flex-row gap-3 bg-gray-300 rounded-md px-4 py-3`}
                >
                  <div className="flex flex-row text-start justify-start gap-2">
                    <FaDotCircle className="my-auto text-lg text-dbase" />
                    <h1 className=" w-full">{item.title}</h1>
                  </div>
                  {activeInquiri !== item.id ? (
                    <FaPlus className="text-sm my-auto" />
                  ) : (
                    <FaMinus className="text-sm my-auto" />
                  )}
                </button>

                {/* Expanded Content */}
                <div
                  className={`${
                    activeInquiri === item.id
                      ? "h-fit opacity-100"
                      : "h-0 opacity-0"
                  }  overflow-y-auto  transition-all  duration-300 container flex flex-col justify-between rounded-md relative z-20 w-full bg-gray-300`}
                >
                  <h2 className="mt-3 text-md font-bold text-black">
                    {" "}
                    <HtmlDecoder encodedHtml={item.description} />
                  </h2>
                  {dataInformation.allow_faq_feedback && (
                    <textarea
                      id={`comment${item.id}`}
                      rows={3}
                      maxLength={200}
                      placeholder="Comment"
                      className="h-[100px]  mt-1 mb-2 p-1 w-full  bg-white outline-none border-none rounded-md"
                    ></textarea>
                  )}
                  <div className="mb-3 flex flex-row justify-between gap-5">
                    <div className=" flex flex-col justify-start text-start ">
                      <h2 className=" ">Was this helpful</h2>
                      {item.helpful_count > 0 && (
                        <span className="text-xs text-dgreySeller border-t border-dplaceHolder">
                          {item.helpful_count} user
                          {item.helpful_count > 1 ? "s" : ""} found this article
                          helpful
                        </span>
                      )}
                    </div>
                    <div className=" flex flex-row justify-end gap-3 text-right  ">
                      <button
                        onClick={() => addFeedback(item.id, true)}
                        className={`border px-4 py-2 rounded-md ${
                          item.feedback === "1"
                            ? "bg-dblue text-white"
                            : " bg-dplaceHolder"
                        } border-dgreyProduct text-dgreyProduct`}
                      >
                        <FaThumbsUp />
                      </button>
                      <button
                        onClick={() => addFeedback(item.id, false)}
                        className={`border px-4 py-2 rounded-md ${
                          item.feedback === "0"
                            ? "bg-dblue text-white"
                            : " bg-dplaceHolder"
                        } border-dgreyProduct text-dgreyProduct`}
                      >
                        <FaThumbsDown />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
